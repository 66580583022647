import React, { useEffect, useRef, useState } from 'react'
import Spinwheel from '../common/Spinwheel';
import Select from 'react-select';
import { getVendorByGstApi, getVendorByPANApi, getvendorsSuggetsonApi, statesAndCitiesApi } from '../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
// import AsyncCreatableSelect from 'react-select/async-creatable';
// import Autocomplete from 'react-autocomplete'

function VenderAddModal(props) {
  const { formik, addLoader, modalId } = props;
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const otherCityRef = useRef();
  // const [vendorSuggestion, setVendorSuggestion] = useState([])

  const statesAndCities = async (stateId = "") => {
    try {
      const res = await statesAndCitiesApi(stateId)
      if (res.status === 200) {
        let fetchedState = res.data?.data?.state.map(ele => ({ label: ele.state, value: ele }))
        let fetchedCity = res.data?.data?.cities.map(ele => ({ label: ele.city, value: ele }))
        if(stateId){
          fetchedCity.push({ label: "+Add City", value: {city:"+add city",stateId} })
        }
        setOptions(fetchedCity)
        setState(fetchedState);
        setCity(fetchedCity);
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const handleVendorSelect = (e) => {
  //   if (e) {
  //     if (e.__isNew__) {
  //       formik.resetForm();
  //       formik.setFieldValue("gstNumber", e.label);
  //     } else {
  //       formik.setFieldValue("gstNumber", e.value.gstNumber)
  //       formik.setFieldValue("name", e.value.name)
  //       formik.setFieldValue("phone", e.value.phone)
  //       formik.setFieldValue("email", e.value.email)
  //       formik.setFieldValue("address1", e.value.vendorAddressId?.address1)
  //       formik.setFieldValue("address2", e.value.vendorAddressId?.address2)
  //       formik.setFieldValue("city", e.value.vendorAddressId?.city)
  //       formik.setFieldValue("state", e.value.vendorAddressId?.state)
  //       formik.setFieldValue("pinCode", e.value.vendorAddressId?.pinCode)
  //       formik.setFieldValue("bankName", e.value.bankName)
  //       formik.setFieldValue("bankAccountNo", e.value.bankAccountNo)
  //     }
  //   } else {
  //     formik.setFieldValue('gstNumber', '')
  //   }
  // }

  // const filtervendors = async (inputValue, cb) => {
  //   try {
  //     const res = await getvendorsSuggetsonApi(inputValue)
  //     if (res.status === 200) {
  //       let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.gstNumber, value: ele }))
  //       cb(fetchedItem);
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  // const vendorOnChange = debounce(filtervendors, 1000);

  // const vendorOptions = (inputValue, callback) => {
  //   vendorOnChange(inputValue, callback);
  // }


  // const filterVendorSuggestion = async (inputValue) => {
  //   try {
  //     if (inputValue) {
  //       const res = await getvendorsSuggetsonApi(inputValue)
  //       if (res.status === 200) {
  //         let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.gstNumber, value: ele }))
  //         setVendorSuggestion(fetchedItem)
  //       }
  //     } else {
  //       setVendorSuggestion([])
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };
  // const vendorSuggestionOnChagne = debounce(filterVendorSuggestion, 1000)

  // const handleSelectVendorSuggestion = (label, value) => {
  //   if (value) {
  //       formik.setFieldValue("gstNumber", value.value.gstNumber)
  //       formik.setFieldValue("name", value.value.name)
  //       formik.setFieldValue("phone", value.value.phone)
  //       formik.setFieldValue("email", value.value.email)
  //       formik.setFieldValue("address1", value.value.vendorAddressId?.address1)
  //       formik.setFieldValue("address2", value.value.vendorAddressId?.address2)
  //       formik.setFieldValue("city", value.value.vendorAddressId?.city)
  //       formik.setFieldValue("state", value.value.vendorAddressId?.state)
  //       formik.setFieldValue("pinCode", value.value.vendorAddressId?.pinCode)
  //       formik.setFieldValue("bankName", value.value.bankName)
  //       formik.setFieldValue("bankAccountNo", value.value.bankAccountNo)
  //     }
  // }

  const handleCheckGSt = async () => {
    try {
      const isVendor = formik?.values?.type=="Vendor"
      if (formik?.values?.gstNumber?.trim() || formik?.values?.panNumber?.trim()) {
        const res = isVendor ?  await getVendorByGstApi(formik.values.gstNumber) : await getVendorByPANApi(formik?.values?.panNumber)
        if (res.status === 200) {
          if (res.data.data && res.data.data.vendors) {
            toast.success(`${isVendor ? "Vendor" :"Sub-Contractor"}  found. Populating details...`)
            const { gstNumber, name, phone, email, vendorAddressId, bankName, bankAccountNo, IFSC } =  res.data.data.vendors
            formik.setFieldValue("gstNumber", gstNumber || "")
            formik.setFieldValue("name", name)
            formik.setFieldValue("phone", phone)
            formik.setFieldValue("email", email)
            formik.setFieldValue("address1", vendorAddressId?.address1)
            formik.setFieldValue("address2", vendorAddressId?.address2)
            formik.setFieldValue("city", vendorAddressId?.city)
            formik.setFieldValue("state", vendorAddressId?.state)
            formik.setFieldValue("pinCode", vendorAddressId?.pinCode)
            formik.setFieldValue("bankName", bankName)
            formik.setFieldValue("bankAccountNo", bankAccountNo)
            formik.setFieldValue("IFSC", IFSC)
            formik.setFieldValue("type", isVendor ? "Vendor" :"Sub-Contractor")
          }
          else{
            toast.success(`${isVendor ? "Vendor" :"Sub-Contractor"} not found. Please enter details to create new.`)
            const values = {gstNumber: formik?.values?.gstNumber ||"",panNumber: formik?.values?.panNumber ||"" ,name : "",type:isVendor ? "Vendor" :"Sub-Contractor", phone:"", email:"", address1:"", address2:"", city:"", state:"", pinCode:"", bankName:"", bankAccountNo:"",IFSC:""}
            formik.setValues(values)
          }
          formik.setFieldValue('isDisabled', false)
        }
      } else {
        if(formik?.values?.type){
          if(isVendor){
            formik?.setFieldError("gstNumber", 'GST is required ')
          }else{
            formik?.setFieldError("panNumber", 'PAN is required ')
          }
        }else{
          formik?.setFieldError("type", 'Type is required ')
        }
      }
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    statesAndCities();
  }, [])

  const vendorTypeOptions=[
    {label:"Vendor",value:"Vendor"},
    {label:"Sub-Contractor",value:"Sub-Contractor"},
  ]
  

  const handleVendorType =(e)=>{
    formik?.resetForm()
    formik?.setFieldValue("type",e?.value)
  }


const handleInputChange = (input) => {
  setInputValue(input);
  
  // Check if there's no match and dynamically add the +Add City option
  const matchFound = city.some((ele) =>
    ele.label.toLowerCase().includes(input.toLowerCase())
  );

  if (!matchFound && input) {
    setOptions([
      ...city,
      { label: `+ Add "${input}"`, value: "add_city", isNew: true },
    ]);
  } else {
    setOptions(city); // Reset to original options if input matches
  }
};

const handleChange = (selectedOption) => {
  if (selectedOption.isNew) {
    const newCity = { label: inputValue, value: inputValue };
    setOptions((prevOptions) => [...prevOptions, newCity]);
    formik.setFieldValue("city", "+Add City");
    formik.setFieldValue("otherCity", inputValue);
    setTimeout(() => {
      otherCityRef?.current?.focus();
    }, 100);
  } else {
    // Handle existing city selection
    formik.setFieldValue("city", selectedOption.label);
  }
}
  

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                id="confirmModalCloseBtn"
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0">
              <div className="container">
                <h2>{formik?.values?._id ? "Update" : "Add"} {!formik?.values?.type ? "Vendor/Sub-contractor" : formik?.values?.type}</h2>
                <form onSubmit={formik?.handleSubmit} >
                {!formik?.values?._id && <div className="form-details">
                    <label htmlFor="">Type</label>
                    <div className='d-flex align-items-center justify-content-center mb-3 '>
                      <div className='cursor-pointer w-100' >
                        <Select style={{ cursor: "pointer !important" }} className='col w-100' placeholder="Select type..." cacheOptions value={{label:formik?.values?.type,value:formik?.values?.type}} options={vendorTypeOptions} defaultOptions onChange={(e)=>{handleVendorType(e)}} />
                    </div>
                    </div>
                    {formik?.touched?.type && formik?.errors?.type ? (<div className='req-error'>{formik?.errors?.type}</div>) : null}
                  </div>} 
                  {formik?.values?.type && <>
                    {formik?.values?.type == "Vendor" ?
                      <div className="mb-3">
                        <label htmlFor="gstNumber" className="form-label">GST number {formik?.values?.type == "Vendor" && <span className='req-error'>*</span>}</label>
                        {/* <div className='cursor-pointer w-100' >
                        <AsyncCreatableSelect style={{ cursor: "pointer !important" }} className='col w-100' placeholder="Select..." cacheOptions value={{ label: formik.values?.gstNumber, value: formik.values?.gstNumber }} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e) }} isClearable/>
                    </div> */}
                        {/* <Autocomplete
                      getItemValue={(item) => item.label}
                      wrapperStyle={{
                        display: 'block'
                      }}
                      items={vendorSuggestion}
                      renderItem={(item, isHighlighted) =>
                        <div className='cursor-pointer'>
                          <p
                            className={`suggestion-list-item`}
                            style={{ backgroundColor: isHighlighted ? '#B2D4FF' : 'transparent' }}
                          >
                            {item.label || ""}
                          </p>
                        </div>
                      }
                      renderInput={(props) =>
                        <input className='form-control' placeholder='Enter GST' {...props} />
                      }
                      value={formik.values.gstNumber}
                      onChange={(e) => { formik.setFieldValue("gstNumber", e.target.value); vendorSuggestionOnChagne(e.target.value) }}
                      onSelect={(label, val) => handleSelectVendorSuggestion(label, val)}
                      renderMenu={(items, value, style) =>
                        <div className='suggestion-list' children={items} />
                      }
                    /> */}
                        <div className="d-flex justify-content-center align-items-center mb-3 ">
                          <input type="text" className="form-control" id="gstNumber" autoComplete='off' placeholder="Enter GST" name='gstNumber' value={formik?.values?.gstNumber} onChange={(e) => { e.target.value = e.target.value.toUpperCase(); formik?.handleChange(e) }} onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault() }} onBlur={formik?.handleBlur} />
                          {/* <div className='ms-2 px-2 cursor-pointer py-1 border' onClick={() => handleCheckGSt()}> <i class="bi bi-search"></i></div> */}
                          <div className='cursor-pointer' onClick={() => handleCheckGSt()}>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="40" viewBox="0 0 50 50">
                              <path d="M 7 4 C 5.3545455 4 4 5.3545455 4 7 L 4 43 C 4 44.645455 5.3545455 46 7 46 L 43 46 C 44.645455 46 46 44.645455 46 43 L 46 7 C 46 5.3545455 44.645455 4 43 4 L 7 4 z M 7 6 L 43 6 C 43.554545 6 44 6.4454545 44 7 L 44 43 C 44 43.554545 43.554545 44 43 44 L 7 44 C 6.4454545 44 6 43.554545 6 43 L 6 7 C 6 6.4454545 6.4454545 6 7 6 z M 22.5 13 C 17.26514 13 13 17.26514 13 22.5 C 13 27.73486 17.26514 32 22.5 32 C 24.758219 32 26.832076 31.201761 28.464844 29.878906 L 36.292969 37.707031 L 37.707031 36.292969 L 29.878906 28.464844 C 31.201761 26.832076 32 24.758219 32 22.5 C 32 17.26514 27.73486 13 22.5 13 z M 22.5 15 C 26.65398 15 30 18.34602 30 22.5 C 30 26.65398 26.65398 30 22.5 30 C 18.34602 30 15 26.65398 15 22.5 C 15 18.34602 18.34602 15 22.5 15 z"></path>
                            </svg>
                          </div>
                        </div>
                        {formik?.touched?.gstNumber && formik?.errors?.gstNumber ? (<div className='req-error'>{formik?.errors?.gstNumber}</div>) : null}
                      </div>
                      :<>
                      <div className="mb-3">
                        <label htmlFor="panNumber" className="form-label">PAN number <span className='req-error'>*</span></label>
                        <div className="d-flex justify-content-center align-items-center mb-3 ">
                          {console.log("dsfs",formik?.values?.panNumber)}
                          <input type="text" className="form-control" id="panNumber" autoComplete='off' placeholder="Enter PAN" name='panNumber' value={formik?.values?.panNumber || ""} onChange={(e) => { e.target.value = e.target.value.toUpperCase(); formik?.handleChange(e) }} onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault() }} onBlur={formik?.handleBlur} />
                          <div className='cursor-pointer' onClick={() => handleCheckGSt()}>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="40" viewBox="0 0 50 50">
                              <path d="M 7 4 C 5.3545455 4 4 5.3545455 4 7 L 4 43 C 4 44.645455 5.3545455 46 7 46 L 43 46 C 44.645455 46 46 44.645455 46 43 L 46 7 C 46 5.3545455 44.645455 4 43 4 L 7 4 z M 7 6 L 43 6 C 43.554545 6 44 6.4454545 44 7 L 44 43 C 44 43.554545 43.554545 44 43 44 L 7 44 C 6.4454545 44 6 43.554545 6 43 L 6 7 C 6 6.4454545 6.4454545 6 7 6 z M 22.5 13 C 17.26514 13 13 17.26514 13 22.5 C 13 27.73486 17.26514 32 22.5 32 C 24.758219 32 26.832076 31.201761 28.464844 29.878906 L 36.292969 37.707031 L 37.707031 36.292969 L 29.878906 28.464844 C 31.201761 26.832076 32 24.758219 32 22.5 C 32 17.26514 27.73486 13 22.5 13 z M 22.5 15 C 26.65398 15 30 18.34602 30 22.5 C 30 26.65398 26.65398 30 22.5 30 C 18.34602 30 15 26.65398 15 22.5 C 15 18.34602 18.34602 15 22.5 15 z"></path>
                            </svg>
                          </div>
                        </div>
                        {formik?.touched?.panNumber && formik?.errors?.panNumber ? (<div className='req-error'>{formik?.errors?.panNumber}</div>) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="gstNumber" className="form-label">GST number</label>
                        <div className="d-flex justify-content-center align-items-center mb-3 ">
                          <input type="text" className="form-control" id="gstNumber" autoComplete='off' placeholder="Enter GST" name='gstNumber' value={formik?.values?.gstNumber} onChange={(e) => { e.target.value = e.target.value.toUpperCase(); formik?.handleChange(e) }} onKeyDown={(e) => { e.keyCode === 13 && e.preventDefault() }} onBlur={formik?.handleBlur} />
                        </div>
                        {formik?.touched?.gstNumber && formik?.errors?.gstNumber ? (<div className='req-error'>{formik?.errors?.gstNumber}</div>) : null}
                      </div>
                      </> 
                    }
                  </>}

                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">Name <span className='req-error'>*</span></label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="name" placeholder="Enter name" name='name' value={formik?.values?.name} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.name && formik?.errors?.name ? (<div className='req-error'>{formik?.errors?.name}</div>) : null}
                  </div>
                  {formik?.values?.type != "Vendor" &&
                  <div className="mb-3">
                    <label htmlFor="nickName" className="form-label">Nick name</label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="nickName" placeholder="Enter nick name" name='nickName' value={formik?.values?.nickName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.nickName && formik?.errors?.nickName ? (<div className='req-error'>{formik?.errors?.nickName}</div>) : null}
                  </div>}

                  <div className="mb-3">
                    <label htmlFor="phone" className="form-label">Phone <span className='req-error'>*</span> </label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="phone" placeholder="Enter phone" name='phone' value={formik?.values?.phone} onChange={(e)=>!isNaN(e?.target.value) && formik?.handleChange(e)} onBlur={formik?.handleBlur} />
                    {formik?.touched?.phone && formik?.errors?.phone ? (<div className='req-error'>{formik?.errors?.phone}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">Email <span className='req-error'>*</span></label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="email" placeholder="Enter email" name='email' value={formik?.values?.email} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.email && formik?.errors?.email ? (<div className='req-error'>{formik?.errors?.email}</div>) : null}
                  </div>

                  {formik?.values?.type != "Vendor" &&
                  <div className="mb-3">
                    <label htmlFor="specialization" className="form-label">Specialization</label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="specialization" placeholder="Enter specialization" name='specialization' value={formik?.values?.specialization} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.specialization && formik?.errors?.specialization ? (<div className='req-error'>{formik?.errors?.specialization}</div>) : null}
                  </div>}

                  <div className="mb-3">
                    <label htmlFor="state" className="form-label">State <span className='req-error'>*</span> </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      // defaultValue={{ label: formik?.values?.state }}
                      value={{ label: formik?.values?.state }}
                      placeholder="Select state..."
                      isSearchable={true}
                      name="state"
                      options={state}
                      isDisabled={formik.values.isDisabled}
                      onChange={(e) => {
                   
                        statesAndCities(e?.value?._id)
                        formik.setFieldValue("city", "")
                        formik.setFieldValue("state",e.label)
                        formik.setFieldValue("stateId", e?.value?._id)
                      }}
                    />
                    {/* <input type="text" className="form-control" id="state" placeholder="Enter State" name='state' value={formik?.values?.state} onChange={formik?.handleChange} onBlur={formik?.handleBlur} /> */}
                    {formik?.touched?.state && formik?.errors?.state ? (<div className='req-error'>{formik?.errors?.state}</div>) : null}
                  </div>

                  <div className="mb-3">
      <label htmlFor="city" className="form-label">
        City <span className="req-error">*</span>
      </label>
      <Select
        className="basic-single"
        classNamePrefix="select"
        value={{ label: formik?.values?.city }}
        placeholder="Select city..."
        isSearchable={true}
        options={options}
        onInputChange={handleInputChange}
        onChange={handleChange}
        noOptionsMessage={() =>
          inputValue ? `No results found for "${inputValue}"` : "No options available"
        }
      />
      {formik?.touched?.city && formik?.errors?.city ? (
        <div className="req-error">{formik?.errors?.city}</div>
      ) : null}
    </div>
                  {
                    formik?.values?.city?.toLowerCase()=="+add city" &&  <div className="mb-3">
                    <label htmlFor="city" className="form-label">Add City<span className='req-error'>*</span></label>
                    <input
                    ref={otherCityRef}
                        className="form-control col"
                        placeholder="Enter..."
                        value={formik?.values?.otherCity || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="otherCity"
                      />
                    {formik?.touched?.otherCity && formik?.errors?.otherCity ? (<div className='req-error'>{formik?.errors?.otherCity}</div>) : null}
                  </div>
                  }
                

                  <div className="mb-3">
                    <label htmlFor="pinCode" className="form-label">Pin code <span className='req-error'>*</span> </label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="pinCode" placeholder="Enter Pin code" name='pinCode' value={formik?.values?.pinCode} onChange={(e)=>!isNaN(e?.target.value) && formik?.handleChange(e)} onBlur={formik?.handleBlur} />
                    {formik?.touched?.pinCode && formik?.errors?.pinCode ? (<div className='req-error'>{formik?.errors?.pinCode}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="address1" className="form-label">Address1 <span className='req-error'>*</span></label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="address1" placeholder="Enter address1" name='address1' value={formik?.values?.address1} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.address1 && formik?.errors?.address1 ? (<div className='req-error'>{formik?.errors?.address1}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="bankAccountNo" className="form-label">Address2 <span className='req-error'>*</span> </label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="address2" placeholder="Enter address2" name='address2' value={formik?.values?.address2} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.address2 && formik?.errors?.address2 ? (<div className='req-error'>{formik?.errors?.address2}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="bankName" className="form-label">Bank name <span className='req-error'>*</span> </label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="bankName" placeholder="Enter bank name" name='bankName' value={formik?.values?.bankName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.bankName && formik?.errors?.bankName ? (<div className='req-error'>{formik?.errors?.bankName}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="bankAccountNo" className="form-label">Bank account <span className='req-error'>*</span> </label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="bankAccountNo" placeholder="Enter bank account" name='bankAccountNo' value={formik?.values?.bankAccountNo} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.bankAccountNo && formik?.errors?.bankAccountNo ? (<div className='req-error'>{formik?.errors?.bankAccountNo}</div>) : null}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="IFSC" className="form-label">Bank IFSC <span className='req-error'>*</span> </label>
                    <input type="text" disabled={formik.values.isDisabled} className="form-control" id="bankIFSC" placeholder="Enter bank account" name='IFSC' value={formik?.values?.IFSC} onChange={e => {e.target.value = e.target.value.toUpperCase(); formik?.handleChange(e)}} onBlur={formik?.handleBlur} />
                    {formik?.touched?.IFSC && formik?.errors?.IFSC ? (<div className='req-error'>{formik?.errors?.IFSC}</div>) : null}
                  </div>

                  <button type="submit" disabled={addLoader} className="btn btn-primary">
                    {addLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VenderAddModal