import React, { useEffect, useState } from 'react'
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import { addInventoryItemApi, deleteInventoryItemByIdApi, getInventoryApi, updateInventoryItemApi } from '../../utils/ApiEndpointFunctions';
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';
import { S3_URL } from '../../utils/BaseUrl';
import InventoryItemModal from '../../components/modal/inventoryItemModal';
import { useFormik } from 'formik';
import { inventoryItemInitialValue, inventoryItemValidationSchema } from '../../utils/Validation';
import StatusModal from '../../components/modal/StatusModal';
import ConfirmationModal from '../../components/modal/ConfirmationModal';

function ViewInventory(props) {
  const [inventory, setInventory] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  // let [selectedPage, setSelectedPage] = useState(1);
  const navigate = useNavigate();
  const { userDetails } = useUserContext();
  const limit = 10;
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const inventoryItemModalId = "inventoryItemModalId"
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModal"
  const [disableItem,setDisableItem] = useState(false)
  const [selectItem,setSelectItem] = useState({name:"",_id:""})


  const handleChange = async (event) => {

    let search1 = event.target.value;
    setSearchParams({"page": 1})
    setSearch(search1);
    setLoading(true);
    const res = await getInventoryApi(event.target.value, limit, 1);
    if (res.status === 200) {
      const totalData = res.data?.data?.totalData
      setTotalCount(totalData);
      setPage(Math.ceil(totalData / limit));
      setInventory(res.data?.data?.data);

      // setSelectedPage(1);
    }
    setLoading(false);
  };

  const debouncedOnChange = debounce(handleChange, 800);

  const getInventoryDetails = async () => {
    setLoading(true);
    try {
      const page = searchParams.get("page") || 1
      const res = await getInventoryApi(search, limit, page);
      if (res.status === 200) {
        const totalData = res.data?.data?.totalData
        setTotalCount(totalData);
        setPage(Math.ceil(totalData / limit));
        setInventory(res.data?.data?.data);
      }
    } catch (error) {
      console.log(error)
    }
    setLoading(false);
  }


  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;
    setSearchParams({"page": currentPage})
    // selectedPage = currentPage;
    // getInventoryDetails();
    // setSelectedPage(currentPage)
  };

  const handleInventoryItem =async(values)=>{
    setDisableItem(true);
    try {
      let payload ={...values}
      payload.name = values?.name?.name
      let isNew = !values?._id
      const res = isNew ? await addInventoryItemApi(payload) : await updateInventoryItemApi(payload);
      if (res.status === 200) {
        getInventoryDetails()
        toast.success(res?.data?.message)
        document.getElementById(inventoryItemModalId).click()
      }
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message || "Failed to update")
    }
    setDisableItem(false);
  }

  const inventoryFormik = useFormik({
    initialValues: inventoryItemInitialValue,
    validationSchema:inventoryItemValidationSchema,
    onSubmit:handleInventoryItem
  })


  useEffect(() => {
    getInventoryDetails()
  }, [searchParams.get('page')])

 const handleRemoveItem =async()=>{
  const _id = selectItem?._id
  if(_id){
    setDisableItem(true);
    try {
      const res =  await deleteInventoryItemByIdApi(_id);
      if (res.status === 200) {
        getInventoryDetails()
        toast.success(res?.data?.message)
        document.getElementById(confirmModalId).click()
        document.getElementById("statusModalBtn").click()
      }
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message || "Failed to remove")
    }
    setDisableItem(false);
  }
 }

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">Inventory management</h3>
                </div>
              </div>}
              <div className="d-flex justify-content-end align-items-center">
                {/* {userDetails?.roleId?.permission.includes("uploadWrite") && <div><button className="create-order" onClick={() => navigate("/upload/add")}>Upload Document</button></div>} */}
                <div className="d-flex align-items-center">
                  <nav className="navbar bg-body-tertiary">
                    <form role="search" className="container-fluid">
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon1"><i className="bi bi-search"></i></span>
                        <input type="text" className="form-control" placeholder="Search inventory" aria-label="Username" aria-describedby="basic-addon1" onChange={debouncedOnChange} />
                      </div>
                    </form>
                  </nav>
                  {userDetails?.roleId?.permission?.includes("inventoryWrite") && <div>
                  <button className='create-order' data-bs-toggle="modal" onClick={inventoryFormik.resetForm}  data-bs-target={`#${inventoryItemModalId}`}>Add</button>
                  </div>}
                 

                </div>
              </div>
              <table className="table d-table mt-4">
                <thead>
                  <tr>
                    <th scope="col-1">NAME</th>
                    <th scope="col-1">HSN CODE</th>
                    <th scope="col-1">UNIT</th>
                    {/* <th scope="col-1">TAX(%)</th> */}
                    <th scope="col-1">Type</th>
                    <th scope="col-1">Track</th>
                    <th scope="col-1">Available quantity</th>
                    <th scope="col-1">Min Qty Alert</th>
                    <th scope="col-2">Action</th>
                  </tr>
                </thead>
                {
                  loading ?
                    <tbody >
                      <tr>
                        <td colSpan={12}>
                          <div className="d-flex justify-content-center">
                            <Spinwheel />
                          </div>
                        </td>
                      </tr>
                    </tbody> :
                    <>
                      <tbody>

                        {inventory?.length > 0 ? inventory?.map((ele, index) => {
                          return (
                            <tr key={index}>
                              <td>{ele?.name}</td>
                              <td>{ele?.hsnCode}</td>
                              <td>{ele?.unitType}</td>
                              <td>{ele?.itemType}</td>
                              <td>{ele?.isTrack ? "Yes" : "No"}</td>
                              {/* <td>{ele?.tax}</td> */}
                              <td ><button className={`${ele?.availableQty <= ele?.minQty ? "reject" :"approve"}  cursor-text text-capitalize `}>{ele?.availableQty}</button> </td>
                              <td >{ele?.minQty}</td>
                              <td>
                                <div>
                                  <div className="dropdown">
                                    <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                    <ul className="dropdown-menu">
                                      {<li className='dropdown-item' data-bs-toggle="modal" onClick={() => inventoryFormik.setValues({ ...ele, addedQty: 0 })} data-bs-target={`#${inventoryItemModalId}`}>Edit</li>}
                                      {userDetails?.roleId?.permission.includes("inventoryRead") &&  <li className='dropdown-item' onClick={()=>navigate(`/inventory/report/${ele?._id}`)}>View Report</li>}
                                      {userDetails?.roleId?.permission.includes("inventoryDelete") &&  <li className='dropdown-item' data-bs-toggle="modal" onClick={() => setSelectItem({name:ele?.name, _id:ele?._id })} data-bs-target={`#${confirmModalId}`}>Delete</li>}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>)
                        }) : <tr className='text-center'>
                          <td colSpan="12">
                            <h1>Data Not Found</h1>
                          </td>
                        </tr>}
                      </tbody></>}
              </table>

            </div>
          </div>

          {totalCount > limit ? (
            <div className="d-flex justify-content-center">
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={page}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName={"active"}
                forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <InventoryItemModal modalId={inventoryItemModalId} formik={inventoryFormik} disable={disableItem} />
        <ConfirmationModal modalId={confirmModalId} handleConfirm={handleRemoveItem} message={<>Are you sure you want to <br /> Remove ?</>} />
        <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
        <StatusModal
          modalId={statusModalId}
          message={`${selectItem.name || ""} is successfully removed.`}
        />
      </div>
    
    </>
  )
}

export default ViewInventory