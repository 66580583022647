import { debounce } from "lodash";
import { getInventoryApi, getItemSuggestionApi, getItemsApi,getUnitTypeApi } from "../../utils/ApiEndpointFunctions";
import Spinwheel from "../common/Spinwheel"
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { formatPrice } from "../../utils/HelperFuncitons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from 'react-select';
// import Autocomplete from "react-autocomplete";


function GinItemAddModal(props) {
  const { formik, addLoader, modalId, type, removeItem, selectItem, vendorId } = props;
  const location = useLocation()
  const [isAddBtn, setIsAddBtn] = useState(true)
  const [units,setUnits] = useState([])

  const paths = location.pathname?.split("/")

  console.log("selectItem",selectItem,formik?.values);
  

  const filteritems = async (inputValue, cb) => {
    try {
      const res = await getInventoryApi(inputValue)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.data?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const itemsOnChange = debounce(filteritems, 1000);

  const itemsOptions = (inputValue, callback) => {
    itemsOnChange(inputValue, callback);
  }


const handleUnitType =(e)=>{
  // console.log("handleUnitType",e);
  formik.setFieldValue("unitType",e?.value)
}


const handleItemSelect = (e) => {
   console.log("handleItemSelect",e);
   const itemDetails = {...e?.value,inventoryItemId:e.value?._id}
   delete itemDetails._id
   formik.setFieldValue("name", e.value?.name);
   formik.setFieldValue("itemDetails", itemDetails);
   formik.setFieldValue("localName", e.value?.localName);
   formik.setFieldValue("description", e.value?.description);
   formik.setFieldValue("itemType", e.value?.itemType);
   formik.setFieldValue("availableQty", e.value?.availableQty);
   formik.setFieldValue("hsnCode", e.value?.hsnCode);
   formik.setFieldValue("unitType", e.value?.unitType);
   formik.setFieldValue("tax", e.value?.tax);
   formik.setFieldValue("inventoryItemId", e.value?._id);
 }

  return (
    <>
      <div
        className="modal fade"
        id={modalId}
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" id="confirmModalCloseBtn" className="btn-close1" data-bs-dismiss="modal" aria-label="Close"
                onClick={() => setIsAddBtn(true)}
              ><i className="bi bi-x-lg" ></i></button>
            </div>
            <div className="modal-body ">
              <div className="">
                <div className="bb">
                  <h5>{selectItem ? "Edit" : "Add"} item details</h5>
                </div>
                <form onSubmit={formik?.handleSubmit} className="my-fx">
                  <div className="d-flex justify-content-center align-items-center mb-3 ">
                  <AsyncSelect
                       className="col"
                       placeholder="Select..."
                       cacheOptions
                       loadOptions={itemsOptions}
                       defaultOptions
                       name='name'
                       isDisabled={selectItem}
                       value={formik?.values?.name ? { label: formik?.values?.name, value: formik?.values?.itemDetails } : null}
                       onChange={(e) => { handleItemSelect(e) }}
                     />
                    <span className=" ms-2" style={{ cursor: "pointer" }} onClick={() => { formik?.resetForm(); formik.setFieldValue("isDisabled", !Boolean(isAddBtn)); setIsAddBtn(value => (!value)) }} >
                    </span>
                  </div>
                  <div className="ed-box">
                    <label htmlFor="localName" >Item Local name</label>
                    <input type="text" disabled={true} className="form-control" id="localName" placeholder="Enter local name" name='localName' value={formik?.values?.localName} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.localName && formik?.errors?.localName ? (<div className='req-error'>{formik?.errors?.localName}</div>) : null}
                  </div>

                  <div className="ed-box">
                    <label htmlFor="description" className="form-label">Description</label>
                    <input type="text" disabled={true} className="form-control" id="description" placeholder="Enter description" name='description' value={formik?.values?.description} onChange={formik?.handleChange} onBlur={formik?.handleBlur} />
                    {formik?.touched?.description && formik?.errors?.description ? (<div className='req-error'>{formik?.errors?.description}</div>) : null}
                  </div>
                  <div className={`row row-cols-2"}`}>
                    <div className="ed-box">
                      <label htmlFor="unitType" className="form-label">Unit type </label>
                      <Select style={{ cursor: "pointer !important" }} isDisabled={true} className="w-100" aria-label="Default select example" name='unitType' placeholder="Select unit type" value={{ label: formik?.values?.unitType, value: formik?.values?.unitType }} isSearchable={true} options={units} onChange={(e) => { handleUnitType(e) }} />
                      {formik?.touched?.unitType && formik?.errors?.unitType ? (<div className='req-error'>{formik?.errors?.unitType}</div>) : null}
                    </div>
                  </div>

                  <div className="ed-box">
                    <label htmlFor="hsnCode" className="form-label">HSN code  </label>
                    <input type="text" disabled={true} className="form-control" id="hsnCode" placeholder="Enter HSN code" name='hsnCode' value={formik?.values?.hsnCode}
                      onChange={(e) => {
                        e.target.value =  e?.target?.value?.toUpperCase()?.replace(/[^a-zA-Z0-9 ]/g, "");
                        formik?.handleChange(e);
                      }}
                      onBlur={formik?.handleBlur} />
                    {formik?.touched?.hsnCode && formik?.errors?.hsnCode ? (<div className='req-error'>{formik?.errors?.hsnCode}</div>) : null}
                  </div>
                  <div className="ed-box">
                    <label htmlFor="itemType" className="form-label">Type </label>
                    <input type="text" disabled={true} className="form-control" id="itemType" placeholder="Enter HSN code" name='itemType' value={formik?.values?.itemType}
                      onChange={(e) => {
                        e.target.value =  e?.target?.value?.toUpperCase()?.replace(/[^a-zA-Z0-9 ]/g, "");
                        formik?.handleChange(e);
                      }}
                      onBlur={formik?.handleBlur} />
                    {formik?.touched?.itemType && formik?.errors?.itemType ? (<div className='req-error'>{formik?.errors?.itemType}</div>) : null}
                  </div>


                  <div className="d-flex mt-4 justify-content-end align-items-center">
                    {!selectItem && <button type="submit" disabled={addLoader} className="save"
                      onClick={() => setIsAddBtn(true)}
                    >
                      {addLoader ? <Spinwheel sizeclassName={"spin-small"} /> : "Save"}
                    </button>}
                
                    
                    {type === "ginItem" && selectItem  && <div className="btn btn-secondary ms-3" onClick={() => { removeItem(selectItem) }}>
                      Remove item
                    </div>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default GinItemAddModal