import React, { useEffect, useState } from 'react'
import { approvePurchaseOrderApi, convertToPurchaseApi, getPurchaseOrderByIdApi, makeCopyApi,downloadPOPdf, getDprByIdApi, approveOrRejectVendorDprApi } from '../../utils/ApiEndpointFunctions'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import toast from 'react-hot-toast';
import Spinwheel from '../../components/common/Spinwheel';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';

function ViewDpr() {
  const params = useParams();
  const { userDetails } = useUserContext();
  const { id } = params;
  let addressKey = ["address1", "address2", "city", "state", "pinCode"]
  const [dprDetails, setDprDetails] = useState();
  const navigate = useNavigate();
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModal"
  const confirmMakeAcopy = "confirmMakeAcopy";
  const convertToPurchaseModalId = "convertToPurchaseModalId"
  const [taxItems, setTaxItems] = useState({});
  const [updatedStatus,setUpdatedStatus] = useState(null)
  const [loader, setLoader] = useState({
    approve: false,
    makeAcopy: false,
    convertToPurchase: false
  })
  const [confirmationMsg, setConfirmationMsg] = useState('')
  const [navigationRoute, setNavigationRoute] = useState("")
  const [download,setDownload] = useState(false)
  const location = useLocation();
  
  const previewTaxItems = (items) => {
    let taxItem = {};
    items?.forEach(item => {
      if (taxItem[item?.itemDetails?.tax]?.length > 0) {
        taxItem[item?.itemDetails?.tax].push(item)
      } else {
        taxItem[item?.itemDetails?.tax] = [item];
      }
    })
    setTaxItems(taxItem)
  }

  const getVendorDprById = async (id) => {
    try{
      const res = await getDprByIdApi(id)
      
      if (res.status === 200) {
        const podata = res.data?.data
        setDprDetails(podata);
        previewTaxItems(podata?.items)
      }
    }catch(err){
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")
      }
    }
  }
  

  const avargeTax = (items) => {
    if (items?.length > 0) {
      let tax = 0;
      items.forEach(item => {

        tax += Number(item?.itemDetails.tax)
      })
      return tax / items.length
    }
    return 0;
  }

  const totalPrice = (items) => {

    if (items?.length > 0) {
      let price = 0;
      items.forEach(item => {

        price += Number(item?.unitPrice) * Number(item?.quantity)
      })
      return price
    }
    return 0;
  }

  const totalAmount = (items) => {
    if (items?.length > 0) {
      let amt = 0
      items.forEach(item => {

        amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
      })
      return amt
    }
  }

  const handlePoApproval = async () => {
    try {
      const res = await approveOrRejectVendorDprApi({ id: id,status:updatedStatus })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        setConfirmationMsg(`DPR ${dprDetails?.dprNumber || ""} is successfully ${updatedStatus}.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute('')
        getVendorDprById(id)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const makeAcopyHandler = async () => {
    try {
      const res = await makeCopyApi({ id: dprDetails._id })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmMakeAcopy).click()
        setConfirmationMsg(`Purchase Order ${res.data?.data?.dprDetails[0]?.poNumber || ""} is successfully created.`)
        document.getElementById("statusModalBtn").click()
        if (res.data?.data?.dprDetails?.[0]?._id) {
          setNavigationRoute(`/purchase/update/${res.data?.data?.dprDetails?.[0]?._id}`)
        } else {
          setNavigationRoute("/purchase")
        }

      }
    } catch (error) {
      toast.error("something went wrong");
    }
  }



  const convertTopurchase = async () => {

    setLoader({ ...loader, convertToPurchase: true })
    try {
      const res = await convertToPurchaseApi({ purchaseOrderId: id })
      if (res.status === 200) {
        toast.success(res.data?.message);
        setConfirmationMsg(`Purchase Order ${dprDetails?.poNumber || ""} is successfully converted to purchase.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute(`/challan/Edit/${res.data?.data?._id}`);
      }
    } catch (error) {
      if(error && error?.response?.status === 409 && error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
      }else{
        toast.error("something went wrong");
      }
    }
    setLoader({ ...loader, convertToPurchase: false })
  }

  const addressFormatter = (vendor) => {
    let address = ""

    addressKey.forEach((ele, index) => {
      if (vendor?.[ele]) {
        address += vendor?.[ele] + ((addressKey.length - 1) === index ? "" : ", ");
      }
    });
    return address
  }

  const handleCloseStatusModal = () => {
    if(navigationRoute){
      navigate(navigationRoute)
    }
  }

  useEffect(() => {
    if (id) {
      getVendorDprById(id)
    }
  }, [])

  const getDownloadPO =async(id)=>{
    try {
      setDownload(true)
      const response = await downloadPOPdf(id)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', dprDetails?.poNumber ? `${dprDetails.poNumber}.pdf` :'po.pdf'); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false)
      toast.success("Successfully downloaded");
    } catch (error) {
      setDownload(false)
      toast.error("Failed to download");
    }
  }

  const getItemVolumeQty = (values,isDiameter) => {
    if(values){
      const { number1, number2, length, breath, height, diameter } = values
      if (isDiameter) {
        return Number((Number(number1 || 0) * Number(number2 || 0) * Math.PI * Number(diameter || 0) * Number(diameter || 0) * Number(height || 0)) / 4).toFixed(2)
      } else {
        return Number(Number(number1 || 0) * Number(number2 || 0) * Number(length || 0) * Number(breath || 0) * Number(height || 0)).toFixed(2)
      }
    }
    return 0
    }


  const getVolumeQty = (item) => {
      const {isDiameter,itemQty} = item
      let totalQty = 0;
      if(itemQty){
        itemQty?.forEach(ele=>{
          const { number1, number2, length, breath, height, diameter } = ele
          if (isDiameter) {
            totalQty+= Number(Number((Number(number1 || 0) * Number(number2 || 0) * Math.PI * Number(diameter || 0) * Number(diameter || 0) * Number(height || 0)) / 4).toFixed(2))
          } else {
            totalQty+= Number(Number(Number(number1 || 0) * Number(number2 || 0) * Number(length || 0) * Number(breath || 0) * Number(height || 0)).toFixed(2))
          }
        })
        return Number(totalQty).toFixed(2)
      }

      return 0
  }


  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> Back</span></div>
            <div className="main-content">
              
              <div className="row d-flex justify-content-center">
                <div className="card col-lg-10 col-md-12 col-xxl-11 my-3 p-5">
                
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <h1 className='text-capitalize'>
                        {dprDetails?.vendor?.name ? dprDetails?.vendor?.name : ""}

                      </h1>
                    </div>
                   
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">DPR Number</div>
                    <div>{dprDetails?.dprNumber}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Date</div>
                    <div>{moment(dprDetails?.createDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">GST Number</div>
                    <div>{dprDetails?.vendor?.gstNumber}</div>
                  </div>
                  {dprDetails?.vendor?.type!="Vendor" && <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">PAN number</div>
                    <div className="light">{dprDetails?.vendor?.panNumber}</div>
                  </div>}
            
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Status</div>
                    <div className='text-capitalize'>{dprDetails?.status}</div>
                  </div>
                  
                  <hr />
                  <div className='fs-3 fw-normal mt-2'>Address:</div>
                  <div className='mt-3'>{addressFormatter(dprDetails?.vendor?.vendorAddressId)}</div>
                  <hr />
                  <div className='fs-3 fw-normal'>
                    Site details
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">Site name</div>
                    <div>{dprDetails?.site?.[0]?.name}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">Site address</div>
                    <div>{dprDetails?.site?.[0]?.addressId?.address1}</div>
                  </div>
                  <hr />
                  <div>
                  {dprDetails?.createdBy?.firstName && <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Created By</div>
                    <div className='text-capitalize'>{dprDetails?.createdBy?.firstName || ""} {dprDetails?.createdBy?.lastName || ""}</div>
                  </div>}
                  {dprDetails?.createdAt && <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Created On</div>
                    <div className='text-capitalize'>{dprDetails?.createdAt &&  moment(dprDetails?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                  </div> }
                  {dprDetails?.status!="created" && dprDetails?.submittedBy?.firstName && <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Submitted By</div>
                    <div className='text-capitalize'>{dprDetails?.submittedBy?.firstName || ""} {dprDetails?.submittedBy?.lastName || ""}</div>
                  </div>}
                  {dprDetails?.status!="created" && dprDetails?.submittedOn && <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Submitted On</div>
                    <div className='text-capitalize'>{dprDetails?.submittedOn &&  moment(dprDetails?.submittedOn).format("DD/MM/YYYY HH:mm")}</div>
                  </div> }
                  {dprDetails?.approvedBy?.firstName && <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Approved By</div>
                    <div className='text-capitalize'>{dprDetails?.approvedBy?.firstName || ""} {dprDetails?.approvedBy?.lastName || ""}</div>
                  </div>}
                  {dprDetails?.approvedOn && <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Approved On</div>
                    <div className='text-capitalize'>{dprDetails?.approvedOn &&  moment(dprDetails?.approvedOn).format("DD/MM/YYYY HH:mm")}</div>
                  </div> }
                  </div>
                  <hr/>
                  <h3>
                    Items
                  </h3>
                  {dprDetails?.items?.length > 0 && dprDetails?.items?.map((ele, index) => 
                    <>
                         <div className='d-flex justify-content-between'>
                           <span className='fw-bold'>
                              {ele?.itemDetails?.name}
                            </span>
                        </div>
                        {/* Second Row: Description */}
                        <div>
                          <div className='d-flex justify-content-between'>
                          <div className='d-flex gap-2'>
                            <label htmlFor='isDiameter'>Description:- </label>
                            {ele?.itemDetails?.description}</div>
                          <div className='d-flex gap-2'>
                            <label htmlFor='isDiameter'>Unit:- </label>
                            {ele?.itemDetails?.unitType}</div>
                          </div>
                      
                          <div className='d-flex gap-2 w-100'>
                          <div className='w-100'>
                          <div className='d-flex gap-2'>
                            <label htmlFor='isDiameter'>Commodity Part:- </label>
                            {ele?.commodityPart}</div>
                         </div>
                          <div className='w-100'>
                          <div className='d-flex gap-2'>
                            <label htmlFor='isDiameter'>Commodity Sub Part:- </label>
                            {ele?.commoditySubPart}</div>
                        </div>
                        
                          </div>
                          <div className='w-100 mt-1'>
                            <label htmlFor='workDescription'>Work Description:-</label>
                            {ele?.workDescription}</div>
                        </div>

                      <div className='w-100 mt-1'>
                        <div className="table-responsive">
                          <table className="table table-bordered" >
                            <thead>
                              <tr>
                                <th >Sub Part</th>
                                <th>No1</th>
                                <th>No2</th>
                                {!ele?.isDiameter && <>
                                  <th>L</th>
                                  <th>B</th>
                                </>}
                                <th>H</th>
                                {ele?.isDiameter && <th>D</th>}
                                <th>QTY</th>
                                <th >Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              
                              {ele?.itemQty?.map((item, qtyIndex) => (
                                <tr key={qtyIndex} >
                                  <td >{item?.subPart} </td>
                                  <td >{item?.number1} </td>
                                  <td >{item?.number2} </td>
                                  {!ele?.isDiameter && <>
                                  <td >{item?.length} </td>
                                  <td >{item?.breath} </td>
                                  </>}
                                  <td >{item?.height} </td>
                                  {ele?.isDiameter && <td >{item?.diameter} </td> }
                                  <td>{getItemVolumeQty(item,ele?.isDiameter) }</td>
                                  <td >{item?.remarks} </td>

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className='d-flex gap-2'>
                        <label htmlFor='isDiameter'>Total Quantity:- </label>
                        {getVolumeQty(ele)}</div>
                      <hr />
                    </>
                  )}
                
                  <hr />           
                  {dprDetails?.remarks && <>
                    <div className='fs-3 fw-normal'>
                      Remarks:
                    </div>
                    <div className=''>{dprDetails?.remarks}</div>
                    <hr />
                    </>}

                    
                  <div className="d-flex justify-contend-start gap-2">
                  {!["approved","rejected"]?.includes(dprDetails?.status)   && userDetails?.roleId?.permission.includes("dprApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary"  onClick={()=>navigate(`/dpr/update/${dprDetails?._id}`)}>Edit</div>
                    </div>}

                    {dprDetails?.status!== "created" && dprDetails?.status!== "approved" && dprDetails?.status!== "rejected" && userDetails?.roleId?.permission.includes("dprApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={()=>setUpdatedStatus("approved")}>Approve</div>
                    </div>}
                    {/* {dprDetails?.status!== "rejected" && userDetails?.roleId?.permission.includes("dprDecline") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={()=>setUpdatedStatus("rejected")}>Reject</div>
                    </div>} */}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal modalId={confirmModalId} handleConfirm={handlePoApproval} message={<>Are you sure you want to <br /> {updatedStatus} ?</>} />
      <ConfirmationModal modalId={confirmMakeAcopy} handleConfirm={makeAcopyHandler} message={<>Are you sure you want to <br /> Make a Copy ?</>} />
      <ConfirmationModal modalId={convertToPurchaseModalId} handleConfirm={convertTopurchase} message={<>Are you sure you want to convert <br /> to  purchase ?</>}/>
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleCloseStatusModal}
      />
    </>
  )
}

export default ViewDpr