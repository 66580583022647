import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createVoucherApi, fileUploadApi, getVoucherByIdApi, getVoucherSuggestionNameApi, updateVoucherApi, getVoucherRemainingAmountApi } from '../../utils/ApiEndpointFunctions';
import { useFormik } from 'formik';
import { voucherInitialValue, voucherValidationSchema } from '../../utils/Validation';
import { useNavigate, useParams,useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import toast from 'react-hot-toast';
import { S3_URL } from '../../utils/BaseUrl';
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import { debounce } from 'lodash';
// import AsyncSelect from 'react-select/async';
import Spinwheel from '../../components/common/Spinwheel';
import moment from 'moment';
import { convertByteToMB, formatPrice } from '../../utils/HelperFuncitons';
import { useUserContext } from '../../context/userContext';
import StatusModal from '../../components/modal/StatusModal';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import Autocomplete from 'react-autocomplete'
import { FILE_ACCEPT, FILE_SIZE_ERROR, INVALID_FILE_FORMAT_ERROR_MESSAGE, MAX_FILE_SIZE } from '../../utils/constant';

function AddOrUpdateVoucher(props) {
  const params = useParams();
  const { id } = params;
  const sigCanvas = useRef({});
  const [submitClick, setSubmitClick] = useState(false);
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);
  const {userDetails} = useUserContext();
  const submitConfirmModal = "submitConfirmModal1"
  const statusModalId = "statusModal1"
  const location = useLocation();
  const [confirmationMsg, setConfirmationMsg] = useState("")
  const [suggestionList, setSuggestionList] = useState([])
  const [confirmModalMsg, setConfirmModalMsg] = useState(<>Are you sure you want to <br /> Submit ?</>)
  const [isSubmitvoucher, setIsSubmitvoucher] = useState(false)
  const [okbtn, setOkbtn] = useState("")

  const clear = () => {
    sigCanvas.current.clear();
  };

  const save = async () => {
    try {
      const canvas = sigCanvas.current.getTrimmedCanvas();
      const blob = await new Promise(resolve => canvas.toBlob(resolve));
      const formData = new FormData();
      formData.append('file', blob, `signature${new Date().getTime()}.png`);
      formData.append("type", "challan")
      const res = await fileUploadApi(formData)
      if (res.status === 200 && res.data?.status === 1) {
        toast.success("File uploaded");

        voucherFormik.setFieldValue("signature", res.data?.data)
        document.getElementById("signatureClose").click()
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const filtervendors = async (inputValue, cb) => {
  //   try {
  //     const res = await getvendorsApi(inputValue)
  //     if (res.status === 200) {
  //       let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
  //       cb(fetchedItem);
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  // const vendorOnChange = debounce(filtervendors, 1000);

  // const vendorOptions = (inputValue, callback) => {
  //   vendorOnChange(inputValue, callback);
  // }

  // const handleVendorSelect = (e) => {

  //   voucherFormik.setFieldValue("name", e.value?.name);
  // }

  // const fileUploadHandler = async (e, pathName) => {
  //   try {

  //     const file1 = e.target.files[0];
  //       const file = new File([file1], `${new Date().getTime()}_${file1.name}`, {
  // type: file1.type,
    // lastModified: file1.lastModified,
      // });
  //     voucherFormik.setFieldTouched(pathName, true)
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     formData.append("type", "challan")

  //     const res = await fileUploadApi(formData)
  //     if (res.status === 200 && res.data?.status === 1) {
  //       toast.success("File uploaded");
  //       voucherFormik.setFieldValue(pathName, res.data?.data)

  //     }

  //   } catch (error) {
  //     console.log(error)
  //     if (error?.response === undefined) {
  //       toast.error("File size is too large")
  //     }
  //   }

  // }


  const filterSuggestionName = async (inputValue) => {
    try {
      if (inputValue) {
        const res = await getVoucherSuggestionNameApi(voucherFormik?.values?.vendorType,inputValue)
        if (res.status === 200) {
          let fetchedItem = res.data?.data?.suggestion?.map(ele => ({ label: ele, value: ele }))
          setSuggestionList(fetchedItem)
        }
      } else {
        setSuggestionList([])
      }
    } catch (error) {
      console.log(error)
    }
  };
  

  const voucherSubmit = async (values) => {
    try {
      document.getElementById(submitConfirmModal).click()
      setSubmitLoader(true)
      const res = await (id ? updateVoucherApi(values) : createVoucherApi(values))
      if (res.status === 200 && res.data?.status === 1) {
        
        setConfirmationMsg(`Voucher ${res.data?.data?.voucherNumber || ""} is successfully ${id ? "updated." : "created."}`)
        document.getElementById("statusModalBtn1").click()
        toast.success(res.data.message)
      } else {
        if(res.status && res.data?.status === 0) {
          toast.error(res.data?.message, {
            timeduration: 10000
          })
        }
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setSubmitLoader(false)
  }


  const voucherFormik = useFormik({
    initialValues: voucherInitialValue,
    onSubmit: voucherSubmit,
    validationSchema: voucherValidationSchema
  })

  const suggestionOnChange = useMemo(() => { return debounce(filterSuggestionName, 500) }, [voucherFormik.values.vendorType]) 

  const voucherTypeHandler = (e) => {

    if (e.target.value === "cash") {
      voucherFormik.setFieldValue("bankName", "")
      voucherFormik.setFieldValue("chequeNumber", "")
      voucherFormik.setFieldValue("chequeDate", "")
    } else {
      voucherFormik.setFieldValue("signature", "")
    }
    voucherFormik?.handleChange(e)
  }

  useEffect(() => {
    if(!id){
      let amount = location?.state?.amount > 0 ? location?.state?.amount: ""
      voucherFormik.setFieldValue("name", location?.state?.name ? location?.state?.name:"")
      voucherFormik.setFieldValue("challanNumber", location?.state?.number ? location?.state?.number:"")
      voucherFormik.setFieldValue("amount", amount ? Number(amount).toFixed(2) : amount)
    }
  }, [])
  

  const checkValidation = async () => {
    try {
      const errors = Object.keys(voucherFormik.errors)
      if (errors?.length > 0) {
        setSubmitClick(true);
        voucherFormik.handleSubmit()
      } else {
          setSubmitLoader(true)
          const res = await getVoucherRemainingAmountApi({ challanOrPONumber: voucherFormik?.values?.challanNumber,
            expenseType:voucherFormik?.values?.expenseType,
            amount:voucherFormik?.values?.amount })
          
          // console.log(findRemainingAmount.data.data.remainingAmount, 'ye hai tesitng of the user')
          // if ((findRemainingAmount && findRemainingAmount.status == 200 && findRemainingAmount.data.data.remainingAmount) < voucherFormik.values.amount) {
          //   setConfirmModalMsg(<span className='p-3'>PO/Challan was of {formatPrice(findRemainingAmount.data.data.originalAmount) || 0} and Vouchers of total amount of {formatPrice(findRemainingAmount.data.data.totalAmount) || 0} has already been made.  Are you sure you want to continue?</span>)
          // }
          if(res?.status==200){
            if(res?.data?.data?.status==1){
              setIsSubmitvoucher(true)
            } else {
              setOkbtn("no")
            }
            setConfirmModalMsg(<span className='p-3'>{res?.data?.message}</span>)
            setSubmitLoader(false)
          }

        document.getElementById("submitModalBtn1").click()
      }
    } catch (err) {
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      }else{
        toast.error("Something went wrong")

      }
      setSubmitLoader(false)
    }
  }

  const handleConfirmation = ()=>{
    if(isSubmitvoucher){
      setSubmitClick(true); 
      voucherFormik.handleSubmit() 
    }else{
      document.getElementById(submitConfirmModal).click()
    }
  }

  const handleClose = () => {
    navigate("/voucher")
  }

  const getRemainingAmount = async() => {
    try {
      const res = await getVoucherRemainingAmountApi({ challanNumber: voucherFormik?.values?.challanNumber })
      if (res.status === 200) {
        toast.success(res.data?.message)
        setConfirmModalMsg('Submit.')
        document.getElementById(confirmationMsg).click()
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const getVoucherById = async() => {
    try {
        const data = await getVoucherByIdApi(id)
        if(data && data.status === 200){
          const {_id, vendorType, name, challanNumber, voucherType, expenseType, amount, signature, bankName, chequeNumber, chequeDate, isVisibleForOwnerOnly, voucherNumber, remarks} = data.data.data.Voucher;

          voucherFormik.setValues({
            _id, 
            vendorType, 
            name, 
            challanNumber, 
            voucherType, 
            expenseType, 
            amount, 
            signature, 
            bankName, 
            chequeNumber, 
            chequeDate : chequeDate || "", 
            isVisibleForOwnerOnly, 
            voucherNumber,
            remarks
          })
        }

    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong")
    }
  }

  useEffect(() => {
    if(id){
      getVoucherById()
    }
  },[id])

  useEffect(()=>{
    if(!voucherFormik?.values?.vendorType==="owner/site manager"){
      voucherFormik?.setFieldValue("expenseType","outward")
    }
    if(voucherFormik?.values?.vendorType==="owner/site manager" && voucherFormik?.values?.expenseType=="inward"){
      voucherFormik?.setFieldValue("challanNumber","")
    }else{
      voucherFormik?.setFieldValue("expenseType","outward")
    }
  },[voucherFormik?.values])


  const fileUploadHandler = async (e, pathName) => {
    try {

      const file1 = e.target.files[0];

      const file = new File([file1], `${new Date().getTime()}_${file1.name}`, {
        type: file1.type,
        lastModified: file1.lastModified,
      });


      if (file) {

        if (!FILE_ACCEPT.includes(file.type)) {
          toast.error(INVALID_FILE_FORMAT_ERROR_MESSAGE)
          return;
        }

        const fileSizeInMB = convertByteToMB(file.size || 0);

        if (fileSizeInMB > MAX_FILE_SIZE) {
          return toast.error(FILE_SIZE_ERROR)
        }

        // challanFormik.setFieldTouched(pathName, true)
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "voucher_photo")

        const res = await fileUploadApi(formData)
        if (res.status === 200 && res.data?.status === 1) {
          toast.success("File uploaded");
          voucherFormik.setFieldValue(pathName, res.data?.data)

        }
      }

    } catch (error) {
      console.log(error)
      if (error?.response === undefined) {
        toast.error("There was an error uploading your file. Please try again later.")
      }
    }

  }

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="main-content ">
            <div className="dash-nav1">
              <div className="back cursor-pointer" onClick={() => { window.history.back() }}><i className="bi bi-chevron-left"></i> Back</div>
              {/* <div className="d-flex justify-content-end align-items-center">
                <div>
                  <button className="create-order" disabled={submitLoader} onClick={checkValidation}>{submitLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit for Approval"}</button>
                </div>
              </div> */}
              <div className="align-items-center d-block d-lg-flex justify-content-between">
                <h3 className="mb-0 font-blue">{id ? "Edit" : "Create New"} Voucher</h3>
                <div>
                  <button className="create-order" disabled={submitLoader} onClick={(e) => { setOkbtn(""); checkValidation(e) }}>{submitLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Submit for Approval"}</button>
                </div>
              </div>
            </div>

            <div className='dash-table'>
              <div className='container'>
                <div className="row">
                  <div className='col-lg-6'>
                    <div className="vendor-detail order-review ">
                      <h6 className='border-bottom'>Voucher details</h6>
                      <div className='row row-cols-2'>
                      <div className="form-details">
                        <label htmlFor="">Issuer type</label>
                        <select className="form-select" name='vendorType' aria-label="Default select example" value={voucherFormik?.values?.vendorType} onChange={(e) => {voucherFormik?.setFieldValue("name",""); setSuggestionList([]);voucherFormik?.handleChange(e)}} onBlur={voucherFormik?.handleBlur}  >
                          <option value="owner/site manager">Owner/Site Manager</option>
                          <option value="vendor">Vendor</option>
                          <option value="subcontractor">Subcontractor</option>
                          <option value="labour">Labour</option>
                          <option value="staff">Staff</option>
                          <option value="misc">Misc</option>
                        </select>
                        {voucherFormik?.touched?.vendorType && voucherFormik?.errors?.vendorType ? (<div className='req-error'>{voucherFormik?.errors?.vendorType}</div>) : null}
                      </div>
                      <div className="form-details">
                        <label htmlFor="">Voucher type</label>
                        <select className="form-select" name='voucherType' aria-label="Default select example" value={voucherFormik?.values?.voucherType?.toLowerCase()} onChange={(e) => { voucherTypeHandler(e) }} onBlur={voucherFormik?.handleBlur}  >
                          <option value="cash">Cash</option>
                          <option value="cheque">Cheque</option>
                          <option value="draft">Draft</option>
                          <option value="upi">UPI</option>
                        </select>
                        {voucherFormik?.touched?.voucherType && voucherFormik?.errors?.voucherType ? (<div className='req-error'>{voucherFormik?.errors?.voucherType}</div>) : null}

                      </div>

                      </div>
                      <div className="form-details">
                        <label htmlFor="">Expenses type</label>
                        <select className="form-select" name='expenseType' aria-label="Default select example" value={voucherFormik?.values?.expenseType?.toLowerCase()} onChange={(e)=>{voucherFormik?.handleChange(e);if(e?.target?.value=="inward"){voucherFormik.setFieldValue("challanNumber","")}}} onBlur={voucherFormik?.handleBlur}  >
                          <option value="outward">Outward</option>
                          {(voucherFormik?.values?.vendorType==="owner/site manager") && <option value="inward">Inward</option>} 
                        </select>
                        {voucherFormik?.touched?.expenseType && voucherFormik?.errors?.expenseType ? (<div className='req-error'>{voucherFormik?.errors?.expenseType}</div>) : null}
                      </div>

                      <div className="form-details">
                        <label htmlFor="">Name <span className='req-error'>*</span></label>
                        {/* <AsyncSelect className='mb-3 col' placeholder="Select vendor..." cacheOptions value={{ label: voucherFormik.values?.name }} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e,) }} /> */}
                        {/* <input type="text" className="form-control" id="challanNumber" placeholder="Enter " name='name' value={voucherFormik?.values?.name} onChange={voucherFormik?.handleChange} onBlur={voucherFormik?.handleBlur} /> */}
                        <Autocomplete
                          getItemValue={(item) => item.label}
                          wrapperStyle={{
                            display: 'block'
                          }}
                          items={suggestionList}
                          renderItem={(item, isHighlighted) =>
                            <div className='cursor-pointer'>
                              <p
                                className={`suggestion-list-item`}
                                style={{ backgroundColor: isHighlighted ? '#B2D4FF' : 'transparent' }}
                              >
                                {item.label || ""}
                              </p>
                            </div>
                          }
                          renderInput={(props) =>
                            <input className='form-control' placeholder='Enter Name' {...props} />
                          }
                          value={voucherFormik?.values?.name}
                          onChange={(e) => { voucherFormik.setFieldValue("name", e.target.value); suggestionOnChange(e.target.value) }}
                          onSelect={(label, val) => voucherFormik.setFieldValue('name',label)}
                          renderMenu={(items, value, style) =>
                            <div className='suggestion-list' children={items} />
                          }
                        /> 
                        {voucherFormik?.touched?.name && voucherFormik?.errors?.name ? (<div className='req-error'>{voucherFormik?.errors?.name}</div>) : null}

                      </div>



                      <div className="form-details">
                        <label htmlFor="challanNumber" className="form-label">PO/Challan number {voucherFormik?.errors?.challanNumber && <span className='req-error'>*</span>}</label>
                        <input type="text" className="form-control" id="challanNumber" placeholder="Enter " name='challanNumber' disabled={location?.state?.number ||voucherFormik?.values?.expenseType==="inward"} value={voucherFormik?.values?.challanNumber} onChange={voucherFormik?.handleChange} onBlur={voucherFormik?.handleBlur} />
                        {voucherFormik?.touched?.challanNumber && voucherFormik?.errors?.challanNumber ? (<div className='req-error'>{voucherFormik?.errors?.challanNumber}</div>) : null}
                      </div>

                      <div className="form-details">
                        <label htmlFor="amount" className="form-label">Amount <span className='req-error'>*</span></label>
                        <input type="text" className="form-control" id="amount" placeholder="Enter " name='amount' value={(voucherFormik?.values?.amount)} 
                        onChange={(e) => {
                          if (/^\d*\.?\d{0,2}$/.test(e.target.value)) {
                            voucherFormik.handleChange(e);
                          }
                        }} 
                        onBlur={voucherFormik?.handleBlur} />
                        {voucherFormik?.touched?.amount && voucherFormik?.errors?.amount ? (<div className='req-error'>{voucherFormik?.errors?.amount}</div>) : null}
                      </div>

                      <div className="form-details">
                        <label htmlFor="remarks" className="form-label">Remark</label>
                        <textarea className="w-100 form-control" type="text" name={`remarks`} placeholder="Enter payment note..." disabled="" onChange={voucherFormik.handleChange} value={voucherFormik?.values?.remarks}/>
                        {voucherFormik?.touched?.remarks && voucherFormik?.errors?.remarks ? (<div className='req-error'>{voucherFormik?.errors?.remarks}</div>) : null}
                      </div>
                      <div className="d-flex flex-column  gap-2 form-details w-100" >
                        <div className=''>
                          <label className='w-75'>Photo</label>
                          {(voucherFormik?.touched?.photo) && voucherFormik?.errors?.photo ? (<div className='req-error'>{voucherFormik?.errors?.photo}</div>) : null}
                        </div>
                        <div className='border border-2 btn_upload d-flex align-items-center p-0' style={{ width: "65px", height: '65px' }} >
                          <label htmlFor="photo" style={{ cursor: "pointer" }} className='h-100 w-100 d-flex align-items-center justify-content-center'>
                            <input type="file" className='btn btn_upload_file d-none ' name="photo" id='photo' onChange={(e) => { fileUploadHandler(e, "photo") }} accept={FILE_ACCEPT} />
                            {!voucherFormik.values?.photo ? <span className="bi bi-paperclip m-auto"></span> :
                              <img src={S3_URL + voucherFormik.values?.photo} alt="" className='img-fluid' />}
                          </label>
                        </div>
                      </div>

                      {voucherFormik?.values?.voucherType?.toLowerCase() === "cash" ?
                        <div>
                          <div className='d-flex align-items-center'><div className='fs-5 fw-bold'>Signature of recipient </div> &nbsp; <span className='req-error'>*</span></div>
                          <div className='py-2'>
                            <div className="d-flex border px-2 py-1 justify-content-between align-items-center ">
                              <div className="">
                                <div className='border rounded-image d-flex justify-content-center align-items-center'>
                                  <img src={S3_URL + voucherFormik.values?.signature} alt="" style={{ maxHeight: "85%", maxWidth: "85%" }} />
                                </div>
                                {(voucherFormik?.touched?.signature || submitClick) && voucherFormik?.errors?.signature ? (<div className='req-error'>{voucherFormik?.errors?.signature}</div>) : null}

                              </div>

                              <div className='signatureCanvasParent'>
                                <Popup
                                  modal
                                  trigger={<div className='create-order cursor-pointer' >
                                    Add Signature
                                  </div>}
                                  closeOnDocumentClick={false}

                                >
                                  {close => (
                                    <>
                                      <SignaturePad
                                        ref={sigCanvas}
                                        canvasProps={{
                                          className: "signatureCanvas"
                                        }}
                                      />

                                      <div className='bg-dark-soft p-2'>
                                        <button className='btn btn-primary mx-1' id="signatureSave" onClick={save} >Upload</button>
                                        <button className='btn btn-primary mx-1' id="signatureClear" onClick={clear}>Clear</button>
                                        <button className='btn btn-primary mx-1' id="signatureClose" onClick={close}>Close</button>
                                      </div>
                                    </>
                                  )}
                                </Popup>
                              </div>
                            </div>

                          </div>
                        </div>
                        :
                        <>
                          <div className="form-details">
                            <label htmlFor="bankName" className="form-label">Bank name <span className='req-error'>*</span></label>
                            <input type="text" className="form-control" id="bankName" placeholder="Enter " name='bankName' value={voucherFormik?.values?.bankName} onChange={voucherFormik?.handleChange} onBlur={voucherFormik?.handleBlur} />
                            {voucherFormik?.touched?.bankName && voucherFormik?.errors?.bankName ? (<div className='req-error'>{voucherFormik?.errors?.bankName}</div>) : null}
                          </div>


                          <div className="form-details">
                            <label htmlFor="chequeNumber" className="form-label">Cheque number <span className='req-error'>*</span></label>
                            <input type="text" className="form-control" id="chequeNumber" placeholder="Enter " name='chequeNumber' value={voucherFormik?.values?.chequeNumber} onChange={voucherFormik?.handleChange} onBlur={voucherFormik?.handleBlur} />
                            {voucherFormik?.touched?.chequeNumber && voucherFormik?.errors?.chequeNumber ? (<div className='req-error'>{voucherFormik?.errors?.chequeNumber}</div>) : null}
                          </div>

                          <div className="form-details w-100">
                            <label htmlFor="chequeDate">Cheque date <span className='req-error'>*</span></label>
                            <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Create date' className='form-select w-100 cursor-pointer' name='chequeDate' selected={voucherFormik.values?.chequeDate} onChange={(e) => { voucherFormik.setFieldValue("chequeDate", new Date(e).toISOString()) }} />
                            {voucherFormik?.touched?.chequeDate && voucherFormik?.errors?.chequeDate ? (<div className='req-error'>{voucherFormik?.errors?.chequeDate}</div>) : null}
                          </div>
                        </>
                      }
                      {userDetails?.roleId?.name === "owner" && <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={voucherFormik?.values?.isVisibleForOwnerOnly} id="flexCheckDefault" name='isVisibleForOwnerOnly' onChange={(e) => { voucherFormik.setFieldValue("isVisibleForOwnerOnly", e.target.checked) }} />
                        <label className="form-check-label fw-bold" htmlFor="flexCheckDefault">
                          visibility for owner only.
                        </label>
                      </div>}

                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className="order-review">
                      <h6 className='fw-bold'>Preview</h6>
                      <hr />
                      <div>
                        {/* <div className="d-flex justify-content-between mb-1">
                            <div className='fs-5 fw-bold'>Preview</div>
                          </div> */}
                        <div>
                          <div className='fs-5 fw-bold'>
                            {voucherFormik.values?.name}
                          </div>

                          <div className='mt-3 d-flex justify-content-between form-details'>
                            <label>Voucher number</label>
                            <div>{voucherFormik.values?.voucherNumber}</div>
                          </div>
                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Created date</label>

                            <div>{moment(voucherFormik.values?.createdAt).format("DD/MM/YYYY")}</div>
                          </div>
                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>PO/Challan number</label>
                            <div>{voucherFormik.values?.challanNumber}</div>
                          </div>
                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Status</label>
                            <div></div>
                          </div>
                          <hr />
                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Name</label>
                            <div>{voucherFormik?.values?.name}</div>
                          </div>
                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Issuer type</label>
                            <div className='text-capitalize'>{voucherFormik?.values?.vendorType}</div>
                          </div>
                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Voucher type</label>
                            <div className='text-capitalize'>{voucherFormik?.values?.voucherType}</div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Expenses type</label>
                            <div className='text-capitalize'>{voucherFormik?.values?.expenseType}</div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Amount</label>
                            <div>{formatPrice(Number(voucherFormik?.values?.amount))}</div>
                          </div>

                          <div className='mt-3 d-flex justify-content-between  form-details'>
                            <label>Remarks</label>
                            <div>{voucherFormik?.values?.remarks}</div>
                          </div>
                            {voucherFormik?.values?.photo && <>
                                <div className="d-flex justify-content-between mb-1">
                                  <div className='fs-6 fw-bold'>Photo</div>
                                </div>
                                <div className="border border-2 d-flex justify-content-center align-items-center">
                                  <img src={S3_URL + voucherFormik?.values?.photo} alt="" className='img-fluid' style={{ minHeight: "200px" }} />
                                </div>
                              </>}
                          <hr />

                          {voucherFormik?.values?.voucherType?.toLowerCase() !== "cash" ? <>
                            <div className="d-flex justify-content-between mb-1">
                              <div className='fs-6 fw-bold'>bank details</div>
                            </div>

                            <div className='mt-3 d-flex justify-content-between  form-details'>
                              <label>Bank name</label>
                              <div>{voucherFormik?.values?.bankName}</div>
                            </div>

                            <div className='mt-3 d-flex justify-content-between  form-details'>
                              <label>Cheque number</label>
                              <div>{voucherFormik?.values?.chequeNumber}</div>
                            </div>

                            <div className='mt-3 d-flex justify-content-between  form-details'>
                              <label>Cheque date</label>
                              <div>{moment(voucherFormik?.values?.chequeDate).format("DD/MM/YYYY")}</div>
                            </div>
                          </>
                            :
                            <>
                              {voucherFormik?.values?.signature && <>
                                <div className="d-flex justify-content-between mb-1">
                                  <div className='fs-6 fw-bold'>Signature of recipient</div>
                                </div>
                                <div className="border border-2 d-flex justify-content-center align-items-center">
                                  <img src={S3_URL + voucherFormik?.values?.signature} alt="" className='img-fluid' style={{ maxHeight: "200px" }} />
                                </div>
                              </>}
                            </>}
                          <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
                          <ConfirmationModal modalId={submitConfirmModal} handleConfirm={handleConfirmation} message={confirmModalMsg} okbtn={okbtn} />
                          <button className='d-none' id="statusModalBtn1" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
                          <StatusModal
                            modalId={statusModalId}
                            message={confirmationMsg}
                            handleClose={handleClose}
                          />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddOrUpdateVoucher