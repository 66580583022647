import React, { useEffect, useState } from 'react'
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import { approveOrRejectApi, getCashbooksApi } from '../../utils/ApiEndpointFunctions';
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';
import { S3_URL } from '../../utils/BaseUrl';
import { useFormik } from 'formik';
import VoucherFilter from '../../components/modal/VoucherFilter';
import CashbookFilter from '../../components/modal/CashbookFilter';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import DatePicker from "react-datepicker";
import { formatPrice } from '../../utils/HelperFuncitons';
import { useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx-js-style';

function Cashbook(props) {
   const location = useLocation();
   const [cashbook, setCashbook] = useState([])
   const [loading, setLoading] = useState(false)
   const [totalCount, setTotalCount] = useState(null);
   const [page, setPage] = useState(0);
   let [selectedPage, setSelectedPage] = useState(1);
   const navigate = useNavigate();
   const limit = 10;
   const confirmModalId = "conFirmModalID"
   const statusModalId = "statusModal"
   const cashbookFilterModal = "cashbookFilterModalId"
   const { userDetails } = useUserContext();
   const [setectedId, setSelectedId] = useState();
   const [voucherStatus, setVoucherStatus] = useState();
   const [searchParams, setSearchParams] = useSearchParams();
   const [isSearch,setIsSearch] = useState(false)
   const [siteBalance, setSiteBalance] = useState(0);
   const [cashbookFieldError, setCashBookFieldError] = useState(false)
      const [download, setDownload] = useState(false)


   const getCashbook = async (filterReset) => {
      if(filterFormik?.values?.to && filterFormik?.values?.from){
         setLoading(true);
         try {
            let filter = filterReset === "reset" ? ["", "", "", "", "", ""] : [
               filterFormik.values?.vendorType,
               filterFormik.values?.name,
               filterFormik.values?.challanNumber,
               filterFormik.values?.voucherType,
               filterFormik.values?.expenseType,
               `${filterFormik.values?.to ? new Date(filterFormik.values?.to).getTime() + "-" : ""}${filterFormik.values?.from ? new Date(filterFormik.values?.from).getTime() : ""}`
            ];
            const page = searchParams.get("page") || 1
            const res = await getCashbooksApi(...filter, limit, page);
            if (res.status === 200) {
   
               setCashbook(res.data?.data?.Voucher);
               setTotalCount(res.data?.data?.totalData);
               setSiteBalance(res.data?.data?.siteBalance);
               setPage(Math.ceil(res.data?.data?.totalData / limit));
               setIsSearch(true)
               setCashBookFieldError(false)
            }
         } catch (error) {
            console.log(error);
         } finally {
            setLoading(false);
            setIsSearch(true)
         }
      }else{
         setCashBookFieldError(true)
      }
   }

   const handleFilterCashbook = () => {
      if (searchParams.get('page') == 1) {
         getCashbook()
      } else {
         setSearchParams({ "page": 1 })
      }
   }

   const handleVoucherApproval = async () => {
      try {
         const res = await approveOrRejectApi({ id: setectedId, status: voucherStatus })
         if (res.status === 200) {
            toast.success(res.data?.message)
            document.getElementById(confirmModalId).click()
            document.getElementById("statusModalBtn").click()
            getCashbook()
         }
      } catch (error) {
         console.log(error)
         toast.error("something went wrong");
      }
   }


   const handlePageClick = (event) => {
      let currentPage = event.selected + 1;

      const newParams = new URLSearchParams(searchParams);
      newParams.set("page", currentPage);

      if (newParams.has("tab")) {
         newParams.set("tab", newParams.get("tab"));
      }

      setSearchParams(newParams);
   };

   const filterFormik = useFormik({
      initialValues: {
         vendorType: "", name: "", challanNumber: "", voucherType: "", expenseType: "", to: "", from: ""
      }
   })

   useEffect(() => {
      if(filterFormik?.values?.to && filterFormik?.values?.from){
         getCashbook()
      }
   }, [searchParams.get('page')])


   useEffect(()=>{
      if (location?.state?.path == "/cashbook" && location.state?.filterValue){
         filterFormik.values.to = location.state?.filterValue?.to
         filterFormik.values.from = location.state?.filterValue?.from
         filterFormik?.setFieldValue("to", location.state?.filterValue?.to)
         filterFormik?.setFieldValue("from", location.state?.filterValue?.from)
         setIsSearch(true)
         getCashbook()
      }
   },[location])


   const exportToExcel = async () => {
      if (filterFormik?.values?.to && filterFormik?.values?.from) {
         try {
            setDownload(true);
            let filter = [
               filterFormik.values?.vendorType,
               filterFormik.values?.name,
               filterFormik.values?.challanNumber,
               filterFormik.values?.voucherType,
               filterFormik.values?.expenseType,
               `${filterFormik.values?.to ? new Date(filterFormik.values?.to).getTime() + "-" : ""}${filterFormik.values?.from ? new Date(filterFormik.values?.from).getTime() : ""}`
            ];
            const res = await getCashbooksApi(...filter);
            if (res.status === 200) {
               let cashbooks = res.data?.data?.Voucher
               let siteBalance = res.data?.data?.siteBalance
               let totalCount = res.data?.data?.totalCount
              
               const workbook = XLSX.utils.book_new();
               const worksheetData = [];
               const defaultHeight = [];
               const merges = [];
               const boldCellStyle = { font: { bold: true } }
               const borderCellStyle = {
                  border: {
                     right: {
                        style: "thin",
                        color: "000000"
                     },
                     left: {
                        style: "thin",
                        color: "000000"
                     },
                     top: {
                        style: "thin",
                        color: "000000"
                     },
                     bottom: {
                        style: "thin",
                        color: "000000"
                     },
                  }
               }
               const centerWithBoldStyle = {
                  font: { bold: true },
                  alignment: {
                     vertical: "center",
                     horizontal: "center",
                     wrapText: '1', // any truthy value here
                  },
               }

               const defaultZooming = [
                  { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 },
                  { width: 20 }, { width: 20 }, { width: 20 }
               ];

               const addRow = (ele) => {
                  worksheetData.push([
                     { v: ele?.approvedOn ? moment(ele?.approvedOn).format("DD/MM/YYYY") : "", s: borderCellStyle },
                     { v: ele?.createDate ? moment(ele?.createDate).format("DD/MM/YYYY") : '', s: borderCellStyle },
                     { v: ele?.voucherNumber || '', s: borderCellStyle },
                     { v: ele?.name || '', s: borderCellStyle },
                     { v: ele?.expenseType, s: borderCellStyle },
                     { v: ele?.amount ? formatPrice(ele?.amount) : "", s: borderCellStyle },
                     { v: ele?.siteBalance ? formatPrice(ele?.siteBalance) : '', s: borderCellStyle },
                     { v: ele?.remarks ? ele?.remarks : '', s: borderCellStyle },
                  ]);
                  defaultHeight.push({ hpx: 40 }, { hpx: 40 }, { hpx: 40 }, { hpx: 40 }, { hpx: 40 });
               };
               const vendorStartRow = worksheetData.length;
               worksheetData.push([{v:`Cashbook from ${moment(filterFormik.values?.to).format("DD/MM/YYYY")} to ${moment(filterFormik.values?.from).format("DD/MM/YYYY")}`,s: { ...boldCellStyle,...centerWithBoldStyle } }]);
               merges.push({
                  s: { r: vendorStartRow, c: 0 }, // start row and column
                  e: { r: vendorStartRow, c: 7 }  // end row and column
              });
              if(userDetails?.siteId?.companyId?.name){
               const startRow = worksheetData.length;
                 worksheetData.push([{v:`Company ${userDetails?.siteId?.companyId?.name}`,s: { ...boldCellStyle,...centerWithBoldStyle ,...borderCellStyle} }]);
                 merges.push({
                    s: { r: startRow, c: 0 }, // start row and column
                    e: { r: startRow, c: 7 }  // end row and column
                });
              }
              if(userDetails?.siteId?.name){
               const startRow = worksheetData.length;
                   worksheetData.push([{v:`Site ${userDetails?.siteId?.name}`,s: { ...boldCellStyle,...centerWithBoldStyle,...borderCellStyle } }]);
                   merges.push({
                     s: { r: startRow, c: 0 }, // start row and column
                     e: { r: startRow, c: 7 }  // end row and column
                  });
              }
              if(userDetails?.siteId?.companyId?.companyLogo){
                  // const startRow = worksheetData.length;
                  const logoUrl = `${S3_URL}${userDetails?.siteId?.companyId?.companyLogo}`;
                  const logoBuffer = await fetch(logoUrl).then(res => res.arrayBuffer());
                   worksheetData.push([{v:`Company Logo`,s: { ...boldCellStyle,...centerWithBoldStyle } }]);
                   const imageId = workbook.addImage({
                     buffer: logoBuffer,
                     extension: 'png',
                 });

                 worksheet.addImage(imageId, {
                     tl: { col: 0, row: 0 },
                     ext: { width: 200, height: 100 },
                 });

                 worksheet.mergeCells('A1:H5'); // Adjust cells as needed
              }
              
               worksheetData.push(
                  ["Approved","Created","Voucher ID","Particular","Expense type","Amount","Balance", "Remark",].map(item => ({ v: item, s: {...centerWithBoldStyle,...borderCellStyle} }))
               )
               cashbooks.forEach((ele) => {
                  addRow(ele);
               });
               worksheetData.push([
                  { v: 'Site Balance', s: { ...boldCellStyle, ...borderCellStyle } }, { v: "", s: borderCellStyle }, { v: formatPrice(siteBalance), s: { ...boldCellStyle, ...borderCellStyle } },

               ]);
               const startRow = worksheetData.length;
               worksheetData.push([{v:`Powered by MMS`,s: { ...boldCellStyle,...centerWithBoldStyle,...borderCellStyle } }]);
               merges.push({
                  s: { r: startRow, c: 0 }, // start row and column
                  e: { r: startRow, c: 7 }  // end row and column
              });

               const worksheet = XLSX.utils.aoa_to_sheet([]);
               XLSX.utils.sheet_add_aoa(worksheet, worksheetData, { origin: "A1", cellStyles: true });
               worksheet['!cols'] = defaultZooming;
               worksheet['!rows'] = defaultHeight;
               worksheet['!merges'] = merges;
               XLSX.utils.book_append_sheet(workbook, worksheet, 'Cashbook');
               XLSX.writeFile(workbook, 'Cashbook.xlsx');
               setDownload(false);
               toast.success(res.data?.message)
            }
         } catch (error) {
            toast.error(error?.response?.data?.message || "Something went wrong")
            console.log("error", error);
         }
         setDownload(false);

      }


   };



   return (
      <>
         <div className="main-content">
            <div className="container-fluid">
               <div className="row">
                  <div className="main-content">
                     {props.type !== "dashboard" && <div className="dash-nav1">
                        <div className="d-flex justify-content-between align-items-center">
                           <h3 className="mb-0 font-blue">My Cashbook</h3>
                        </div>
                     </div>}
                     <div className="d-flex justify-content-end align-items-center">
                        <div className="d-flex ms-2">
                           <div className="d-block d-md-flex gap-2 align-items-center justify-content-end">
                           <div className="d-flex gap-2 align-items-center justify-content-center m-md-0">
                                 <label htmlFor="date" className="form-label">From:</label>
                                 <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={`form-control cursor-pointer ${cashbookFieldError && "border-danger"}`}
                                    selected={filterFormik.values?.to ? filterFormik.values?.to : null}
                                    onChange={(update) => {
                                       filterFormik.setFieldValue("to", update)
                                    }}
                                    placeholderText='dd/mm/yyyy'
                                    
                                 />
                              </div>
                              <div className=" d-flex gap-2 align-items-center justify-content-center m-md-0">
                              <label htmlFor="date" className="form-label">To:</label>
                                 <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={`form-control cursor-pointer ${cashbookFieldError && "border-danger"}`}
                                    selected={filterFormik.values?.from ? filterFormik.values?.from : null}
                                    onChange={(update) => {
                                       filterFormik.setFieldValue("from", update)
                                    }}
                                    placeholderText='dd/mm/yyyy'
                                 
                                 />
                              </div>
             
                              <button className="btn btn-dark me-2 mb-2 mb-md-0" type="button" onClick={() => { getCashbook() }} >
                                 Search
                              </button>
                              {cashbook?.length > 0 && <button className="btn btn-dark me-2" disabled={download} type="button" onClick={exportToExcel} >
                                Download
                               </button>}
                              {/* <div className="dropdown">
                                 <button className="btn btn-dark me-2" type="button" onClick={() => { filterFormik.resetForm(); getCashbook("reset") }} >
                                    Reset filter
                                 </button>
                                 <button className="btn filter  dropdown-toggle" type="button" data-bs-toggle="modal" data-bs-target={`#${cashbookFilterModal}`}  >
                                    <i className="bi bi-funnel me-2"></i>Filter
                                 </button>
                              </div> */}
                           </div>
                        </div>
                     </div>
                     {filterFormik?.values?.to && filterFormik?.values?.from && isSearch ?  <div className='table-responsive mt-4'>
                        <table className="table table-bordered table-responsive">
                           <thead>
                              <tr>
                                 <th scope='col' colspan="12" className='text-center'>Cashbook</th>
                              </tr>
                              
                              <tr>
                                 <th scope='col' >Approved</th>
                                 <th scope='col' >Created</th>
                                 <th scope='col' >Voucher ID</th>
                                 <th scope='col' >Particular</th>
                                 <th scope='col' >Expense type</th>
                                 <th scope='col' >Amount</th>
                                 <th scope='col' >Balance</th>
                                 <th scope='col' >Remark</th>
                              </tr>
                           </thead>
                           <tbody>
                                       {cashbook?.length > 0 ? cashbook?.map((ele, index) => {
                                          
                                          return (
                                             <tr>
                                                <td>{moment(ele?.approvedOn).format("DD/MM/YYYY")}</td>
                                                <td>{moment(ele?.createDate).format("DD/MM/YYYY")}</td>
                                                <td><Link to={`/voucher/view/${ele?._id}`} state={{path:"/cashbook",filterValue:filterFormik.values}}>{ele?.voucherNumber}</Link></td>
                                                <td title={ele?.name} className='text-capitalize'>{( ele?.name?.length <= 45 ? ele?.name : `${ele?.name?.slice(0, 25)}...`)}</td>
                                                {/* <td>{ ele?.amount}</td> */}
                                                <td className='text-capitalize'>{ele?.expenseType}</td>
                                                <td className='text-end'>{formatPrice(ele?.amount)}</td>
                                                <td className='text-end'>{formatPrice(ele?.siteBalance)}</td>
                                                <td title={ele?.remarks}>{ele?.remarks?.length <= 25 ? ele?.remarks : `${ele?.remarks?.slice(0, 25)}...`}</td>
                                             </tr>)
                                       }) : <tr className='text-center'>
                                          <td colSpan="12">
                                             <h1>Data Not Found</h1>
                                          </td>
                                       </tr>}
                                    </tbody>
                                    <tfoot>
                                       <div className='my-2'>
                                          <h5>Site Balance: {formatPrice(siteBalance)}</h5>
                                       </div>
                                    </tfoot>
                        </table>
                     </div>
                  : <div className='d-flex align-items-center justify-content-center mt-4'>
                  {loading ? <Spinwheel/> :<p className={`fs-4 ${cashbookFieldError && "text-danger"}`}>Please select date range to see cashbook</p>}
                  </div>   
                  }

                  </div>
               </div>

               {totalCount > limit ? (
                  <div className="d-flex justify-content-center">
                     <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={page}
                        previousLabel="< Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName={"active"}
                        forcePage={searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0}
                     />
                  </div>
               ) : (
                  ""
               )}
            </div>
         </div>
         <CashbookFilter modalId={cashbookFilterModal} formik={filterFormik} clickHandler={handleFilterCashbook} />
         <ConfirmationModal modalId={confirmModalId} handleConfirm={handleVoucherApproval}
            message={<>Are you sure you want to <br /> Approve ?</>}
         />
         <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
         <StatusModal
            modalId={statusModalId}
            message={"Your Voucher is approved successfully."}
         />

      </>
   )
}

export default Cashbook