import React, { useEffect, useState } from 'react'
import { approvePurchaseOrderApi, convertToPurchaseApi, getSiteBoqByIdApi, makeCopyApi,downloadPOPdf, approveGinByIdApi, approveOrRejectSiteBoqApi, } from '../../utils/ApiEndpointFunctions'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import toast from 'react-hot-toast';
import Spinwheel from '../../components/common/Spinwheel';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import { S3_URL } from '../../utils/BaseUrl';

function ViewSiteBoq() {
  const params = useParams();
  const { userDetails } = useUserContext();
  const { id } = params;
  let addressKey = ["address1", "address2", "city", "state", "pinCode"]
  const [boqDetails, setBoqDetails] = useState();
  const navigate = useNavigate();
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModal"
  const confirmMakeAcopy = "confirmMakeAcopy";
    const [updatedStatus,setUpdatedStatus] = useState(null)
  const [loader, setLoader] = useState({
    approve: false,
    makeAcopy: false,
    convertToPurchase: false
  })
  const [confirmationMsg, setConfirmationMsg] = useState('')
  const [navigationRoute, setNavigationRoute] = useState("")
  const [download,setDownload] = useState(false)
  const location = useLocation();
  
  const getSiteBoqById = async (id) => {
    try{
      const res = await getSiteBoqByIdApi(id)
      
      if (res.status === 200) {
        const podata = res.data?.data
        setBoqDetails(podata);
      }
    }catch(err){
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")
      }
    }
  }
  

  const handleApproval = async () => {
    try {
      const res = await approveOrRejectSiteBoqApi({ id: id,status:updatedStatus })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        setConfirmationMsg(`BOQ ${boqDetails?.boqNumber || ""} is successfully ${updatedStatus}.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute('')
        getSiteBoqById(id)
      }
    } catch (error) {
      console.log(error)
      if(error && error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        }else{
          toast.error("something went wrong");
        }
    }
  }


  const handleCloseStatusModal = () => {
    if(navigationRoute){
      navigate(navigationRoute)
    }
  }

  useEffect(() => {
    if (id) {
      getSiteBoqById(id)
    }
  }, [])

  const getDownloadPO =async(id)=>{
    try {
      setDownload(true)
      const response = await downloadPOPdf(id)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', boqDetails?.poNumber ? `${boqDetails.poNumber}.pdf` :'po.pdf'); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false)
      toast.success("Successfully downloaded");
    } catch (error) {
      setDownload(false)
      toast.error("Failed to download");
    }
  }

const totalAmount =(items)=>{
   let total =0
   items?.forEach(ele=>{
     total+=Number(ele?.quantity)*Number(ele?.unitPrice)
   })
   return total
}


  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> Back</span></div>
            <div className="main-content">
              
              <div className="row d-flex justify-content-center">
                <div className="card col-lg-10 col-md-12 col-xxl-11 my-3 p-5">
                
                  <div className="d-flex justify-content-between">
                    <div className="">
                    </div>
                   
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">BOQ Number</div>
                    <div>{boqDetails?.boqNumber}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Contract Date</div>
                    <div>{moment(boqDetails?.contractDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Date of Start (as per Contract)</div>
                    <div>{moment(boqDetails?.contractStartDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Date of Completion (as per contract)</div>
                    <div>{moment(boqDetails?.contractDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Date of Start (Actual)</div>
                    <div>{moment(boqDetails?.actualDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Status</div>
                    <div className='text-capitalize'>{boqDetails?.status}</div>
                  </div>
                  <hr />
                  <div className='fs-3 fw-normal'>
                    Site details
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">Site name</div>
                    <div>{boqDetails?.site?.[0]?.name}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">Site address</div>
                    <div>{boqDetails?.site?.[0]?.addressId?.address1}</div>
                  </div>
                  <hr />
                  <div>
                    {boqDetails?.createdBy?.firstName && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Created By</div>
                      <div className='text-capitalize'>{boqDetails?.createdBy?.firstName || ""} {boqDetails?.createdBy?.lastName || ""}</div>
                    </div>}
                    {boqDetails?.createdAt && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Created On</div>
                      <div className='text-capitalize'>{boqDetails?.createdAt && moment(boqDetails?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {boqDetails?.status!="created" && boqDetails?.submittedBy?.firstName && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Submitted By</div>
                      <div className='text-capitalize'>{boqDetails?.submittedBy?.firstName || ""} {boqDetails?.submittedBy?.lastName || ""}</div>
                    </div>}
                    {boqDetails?.status!="created" && boqDetails?.submittedOn && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Submitted On</div>
                      <div className='text-capitalize'>{boqDetails?.submittedOn && moment(boqDetails?.submittedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {boqDetails?.approvedBy?.firstName && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Approved By</div>
                      <div className='text-capitalize'>{boqDetails?.approvedBy?.firstName || ""} {boqDetails?.approvedBy?.lastName || ""}</div>
                    </div>}
                    {boqDetails?.approvedOn && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Approved On</div>
                      <div className='text-capitalize'>{boqDetails?.approvedOn && moment(boqDetails?.approvedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                  </div>
                  <hr />
                  <h3>
                    Details
                  </h3>
                  <div className='table-responsive'>
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                           <th>ID</th>
                          <th scope="col">Item Name and Details</th>
                          <th scope="col">UNIT</th>
                          <th scope="col">Total Quanity</th>
                          <th scope="col">Rate</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody >
                        {boqDetails?.items?.map((ele, index) => {
                          
                          return (
                            <tr key={index}>
                              <td>{ele?.uniqueBoqItemId}</td>
                              <th>{ele?.nameAndDetails}</th>
                              <td>{ele?.unitType}</td>
                              <td>{ele?.quantity}</td>
                              <td>{ele?.unitPrice}</td>
                              <td>{ele?.quantity && ele?.unitPrice && Number(ele?.quantity)*Number(ele?.unitPrice)}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                          <hr />
                          <div className='amount-box p-2 px-3'>
                             <div className="d-flex total justify-content-between align-items-center">
                                <p>Total amount</p>
                                <p>{formatPrice(totalAmount(boqDetails?.items))}</p>
                             </div>
                          </div>           

                    
                  <div className="d-flex justify-contend-start gap-2">

                    {boqDetails?.status!== "created" && boqDetails?.status!== "approved" && boqDetails?.status!== "rejected" && userDetails?.roleId?.permission.includes("boqApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={()=>setUpdatedStatus("approved")}>Approve</div>
                    </div>}
                    {/* {boqDetails?.status!== "rejected" && userDetails?.roleId?.permission.includes("dprDecline") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} onClick={()=>setUpdatedStatus("rejected")}>Reject</div></div>} */}

                    {userDetails?.roleId?.permission.includes("boqUpdate") && boqDetails?.status?.toLowerCase() !== "approved" && <div className="d-flex justify-content-between gap-2 mt-3"> <button className='btn btn-primary' onClick={() => { navigate(`/boq/update/${id}`) }} > Edit</button></div>}

                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal modalId={confirmModalId} handleConfirm={handleApproval} message={<>Are you sure you want to <br /> Approve ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleCloseStatusModal}
      />
    </>
  )
}

export default ViewSiteBoq