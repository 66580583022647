import React, { useEffect, useState } from 'react'
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import { approveOrRejectApi, getVoucherRemainingAmountApi, getVouchersApi } from '../../utils/ApiEndpointFunctions';
import ReactPaginate from 'react-paginate';
import { useFormik } from 'formik';
import VoucherFilter from '../../components/modal/VoucherFilter';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import { voucherFilterInitialvalues, voucherFilterValidationSchema } from '../../utils/Validation';

function Voucher(props) {
  const [vouchers, setVouchers] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const limit = 10;
  const confirmModalId = "conFirmModalIDVoucher"
  const statusModalId = "statusModalVoucher"
  const voucherFilterModal = "voucherFilterModalId"
  const { userDetails } = useUserContext();
  const [selectedVoucher, setSelectedVoucher] = useState({id:null,voucherNo:null});
  const [voucherStatus, setVoucherStatus] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirmModalMsg, setConfirmModalMsg] = useState(<>Are you sure you want to <br /> Submit ?</>)
  const [isSubmitvoucher, setIsSubmitvoucher] = useState(false)
  const [okbtn, setOkbtn] = useState("")
  const [submitLoader, setSubmitLoader] = useState(false)

  const getTimeBeforeNDays = (n) => {
    const currentTime = new Date().getTime();
    return currentTime - n * 24 * 60 * 60 * 1000;
  }



  const getVouchers = async (filterReset) => {
    setLoading(true);

    try {
      let filter = filterReset === "reset" ? ["", "", "", "", "", ""] : [
        filterFormik.values?.vendorType,
        filterFormik.values?.name,
        filterFormik.values?.challanNumber,
        filterFormik.values?.voucherType,
        filterFormik.values?.expenseType,
        filterFormik.values?.dateType === "3" ?
        `${filterFormik.values?.to ? new Date(filterFormik.values?.to).getTime() + "-" : ""}${filterFormik.values?.from ? new Date(filterFormik.values?.from).getTime() : ""}` : 
          filterFormik.values?.dateType === "2" ? `${getTimeBeforeNDays(30) + "-"}${new Date().getTime()}` :
            filterFormik.values?.dateType === "1" ? `${getTimeBeforeNDays(7) + "-"}${new Date().getTime()}` :
              filterFormik.values?.dateType === "0" ? `${getTimeBeforeNDays(1) + "-"}${new Date().getTime()}`:"",
            ];
      const status= filterReset === "reset" ? "" : filterFormik?.values?.status
      const page = searchParams.get("page") || 1
      const res = await getVouchersApi(...filter, limit, page,status);
      if (res.status === 200) {

        setVouchers(res.data?.data?.Voucher);
        setTotalCount(res.data?.data?.totalData);
        setPage(Math.ceil(res.data?.data?.totalData / limit));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleFilterVoucher = () => {
    document.getElementById(voucherFilterModal).click()

    if (searchParams.get('page') == 1) {
      getVouchers()
    } else {
      setSearchParams({ "page": 1 })
    }
  }

  const handleVoucherApproval = async (value) => {
    try {
      const res = await approveOrRejectApi({ id: selectedVoucher?.id, status: voucherStatus,reason:value ||"" })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        document.getElementById("statusModalBtn").click()
        getVouchers()
      }
    } catch (error) {
      console.log(error)
      toast.error(error?.response?.data?.message || "something went wrong");
    }
  }

  const handleConfirmation = (value)=>{
    if(isSubmitvoucher || voucherStatus=="rejected"){
      handleVoucherApproval(value) 
    }else{
      document.getElementById(confirmModalId).click()
    }
  }

  const checkValidation = async (voucher) => {
    try {
      const res = await getVoucherRemainingAmountApi({
        challanOrPONumber: voucher?.challanNumber,
        expenseType: voucher?.expenseType,
        amount: voucher?.amount
      })

      if (res?.status == 200) {
        if (res?.data?.data?.status == 1) {
          setIsSubmitvoucher(true)
        } else {
          setOkbtn("no")
        }
        setConfirmModalMsg(<span className='p-3'>{res?.data?.message}</span>)
      }

      document.getElementById("submitModalBtn1").click()
    } catch (err) {
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")

      }
    }
  }

  const handlePageClick = (event) => {
    let currentPage = event.selected + 1;

    const newParams = new URLSearchParams(searchParams);
    newParams.set("page", currentPage);

    if (newParams.has("tab")) {
      newParams.set("tab", newParams.get("tab"));
    }

    setSearchParams(newParams);
  };

  const filterFormik = useFormik({
    initialValues: voucherFilterInitialvalues,
    validationSchema: voucherFilterValidationSchema
  })

  useEffect(() => {
    getVouchers()
  }, [searchParams.get('page')])

  const customBtnStatusColor =(status)=>{
    if(status.toLowerCase()=="rejected"){
      return {
        color:"black",
        backgroundColor:"#fdc2c2"
      }
    }else{
      return {}
    }
  }

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="main-content">
              {props.type !== "dashboard" && <div className="dash-nav1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 font-blue">Voucher</h3>
                </div>
              </div>}
              <div className="d-flex justify-content-end align-items-center">
                {userDetails?.roleId?.permission.includes("voucherWrite") && <div><button className="create-order" onClick={() => navigate("/voucher/add")}>Add</button></div>}
                <div className="d-flex ms-2">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="dropdown">
                      <button className="btn btn-dark me-2" type="button" disabled={!Object.values(filterFormik?.values).filter(Boolean).length} onClick={() => { filterFormik.resetForm(); getVouchers("reset") }} >
                        Reset filter
                      </button>
                      <button className="btn filter  dropdown-toggle" type="button" data-bs-toggle="modal" data-bs-target={`#${voucherFilterModal}`}  >
                        <i className="bi bi-funnel me-2"></i>Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table className="table d-table mt-4">
                <thead>
                  <tr>
                    <th scope="col">Created Date</th>
                    <th scope="col">Voucher number</th>
                    <th scope="col">Vendor type</th>
                    <th scope="col">Name</th>
                    <th scope="col">Challan/ PO number</th>
                    <th scope="col">Voucher type</th>
                    <th scope="col">Expenses type</th>
                    <th scope="col">Status</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                {
                  loading ?
                    <tbody >
                      <tr>
                        <td colSpan={12}>
                          <div className="d-flex justify-content-center">
                            <Spinwheel />
                          </div>
                        </td>
                      </tr>
                    </tbody> :
                    <>
                      <tbody>

                        {vouchers?.length > 0 ? vouchers?.map((ele, index) => {

                          return (
                            <tr key={index}>
                              <td>{ moment(ele?.createdAt).format("DD/MM/YYYY")}</td>
                              <td>{ele?.voucherNumber}</td>
                              <td className='text-capitalize'>{ele?.vendorType}</td>
                              <td>{ele?.name}</td>
                              <td>{ele?.challanNumber}</td>
                              <td className={ele?.voucherType?.toLowerCase() === "upi" ? "text-uppercase" : "text-capitalize"}>{ele?.voucherType}</td>
                              <td className='text-capitalize'>{ele?.expenseType}</td>
                              <td><button className={`cursor-text text-capitalize ${ele?.status?.toLowerCase() == "approved" ? "approve" : (ele?.status?.toLowerCase() == "rejected" ? "reject" : "sbm")} `} style={customBtnStatusColor(ele?.status)}>{ele?.status}</button></td>
                              <td>
                                <div>
                                  <div className="dropdown">
                                    <button className="dots dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                    <ul className="dropdown-menu">
                                      <li className='dropdown-item' onClick={() => { navigate(`/voucher/view/${ele?._id}`) }}>View</li>
                                      {userDetails?.roleId?.permission.includes("voucherApprove") && !ele?.isApproveReject  && ele?.status?.toLowerCase() != "approved" &&
                                        <li className='dropdown-item' onClick={(e) => { setOkbtn(""); checkValidation(ele); setSelectedVoucher({id: ele?._id}); setVoucherStatus('approved');setConfirmModalMsg(<h1>Are you sure you want to <br/> Approve ?</h1>) }} >Approve</li>}
                                      {userDetails?.roleId?.permission.includes("voucherUpdate") && !ele?.isApproveReject && ele?.status?.toLowerCase() != "approved" &&
                                        <li className='dropdown-item'onClick={() => { navigate(`/voucher/add/${ele?._id}`) }} >Edit</li>}
                                      {userDetails?.roleId?.permission.includes("voucherApproveReject") && !ele?.isReversalVoucher && ele?.status?.toLowerCase() == "approved" &&
                                        <li className='dropdown-item'  data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`}  onClick={(e) => {setSelectedVoucher({id: ele?._id}); setVoucherStatus('rejected');setConfirmModalMsg(<h1>Are you sure you want to <br/> Reject ?</h1>) }} >Reject</li>}
                                    </ul>
                                  </div>
                                </div>
                              </td>
                            </tr>)
                        }) : <tr className='text-center'>
                          <td colSpan="12">
                            <h1>Data Not Found</h1>
                          </td>
                        </tr>}
                      </tbody></>}
              </table>

            </div>
          </div>

          {totalCount > limit ? (
            <div className="d-flex justify-content-center">
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={page}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName={"active"}
                forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <VoucherFilter modalId={voucherFilterModal} formik={filterFormik} clickHandler={handleFilterVoucher} />
      <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`} />
      <ConfirmationModal modalId={confirmModalId} handleConfirm={handleConfirmation} message={confirmModalMsg} okbtn={okbtn} reasonRequired={voucherStatus=='rejected'}/>
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={`Voucher ${selectedVoucher.voucherNo || ""} is successfully ${voucherStatus}.`}
      />

    </>
  )
}

export default Voucher