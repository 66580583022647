import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import DatePicker from "react-datepicker";
import { addVendorDprItemApi, createItemApi, createPurchaseOrderApi, createVendorApi, createVendorDprApi, deleteItemByIdApi, deleteVendorDprItemByIdApi, getItemsApi, getVendorDprById, getPurchaseOrderByIdApi, getVendorDprItemsApi, getvendorsApi, submitAndConvertToPurchaseOrderApi, termsAndConditionsApi, updateItemByIdApi, updatePurchaseOrderApi, updateVendorByIdApi, getDprByIdApi, updateVendorDprApi, submitVendorDprApi } from '../../utils/ApiEndpointFunctions';
import ItemsAddModal from '../../components/modal/ItemsAddModal';
import { useFormik } from 'formik';
import { addItemInitialValue, addItemValidationSchema, dprAddInitalValue, dprAddValidationSchema, poAddInitalValue, poAddValidationSchema, vendorAddInitailValue, vendorAddValidationSchema } from '../../utils/Validation';
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import VendorAddModal from "../../components/modal/VenderAddModal";
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import { useUserContext } from '../../context/userContext';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import Select from 'react-select';

function AddDailyProgressReport() {
  const [addItemLoader, setAddItemLoader] = useState(false)
  const [addLoader, setAddLoader] = useState(false)
  const [convertLoader, setConvertLoader] = useState(false)
  const addItemModalId = 'addItemModalId';
  const addVendorModalId = 'addVendorModalId';
  const updateItemModalId = "UpdateItemModalId";
  const ItemDeleteModalId = 'itemDeleteModalId';
  const submitConfirmModal = "submitConfirmModal1"
  const statusModalId = "statusModal1"
  const convertConfirmModal = "convertConfirmModal1"
  const convertStatusModalId = "covertStatusModal1"
  const [PoEditId, setPoEditId] = useState(null)
  const navigate = useNavigate();
  const params = useParams();
  const { dprId } = params;
  const [selectItemId, setSelectItemId] = useState();
  const [poDetails, setPoDetails] = useState({})
  const { userDetails } = useUserContext();
  const [taxItems, setTaxItems] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [terms, setTerms] = useState()
  const [reLoadVendor, setReLoadVendor] = useState(false)
  const [isSaveDraft, setIsSaveDraft] = useState({status:false,index:null})

  //items =================================================================

  const handleItemDelete = async () => {
    try {
      const res = await deleteItemByIdApi(selectItemId)
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(ItemDeleteModalId).click()
        let items = dprFormik?.values?.items?.filter(ele => { return (ele.itemId !== selectItemId) })
        dprFormik.setFieldValue("items", items)
      }
    } catch (error) {
      console.log(error)
      toast.error("something went wrong");
    }
  }

  const filteritems = async (inputValue, cb) => {
    try {
      const res = await getItemsApi(inputValue, "", "", dprFormik.values.vendorId)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.items?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };
  const itemsOnChange = debounce(filteritems, 1000);

  const itemsOptions = (inputValue, callback) => {
    itemsOnChange(inputValue, callback);
  }

  const itemSelectHandler = (e) => {

    const item = {
      selected: true,
      itemId: e?.itemDetails?._id,
      vendorDprItemId: e?._id,
      quantity: '',
      unitPrice: '',
      unitType: e?.unitType || "",
      otherUnit: "",
      workDescription: "",
      commodityPart:"",
      commoditySubPart:"",
      isDiameter: false,
      itemDetails: e?.itemDetails,
      itemQty: [
        {
          length: 0,
          breath: 0,
          height: 1,
          number1: 1,
          number2: 1,
          diameter: 0,
          remarks: "",
          subPart: "",
        }
      ]
    }
    dprFormik.setFieldValue("items", [...dprFormik.values.items, item])
  }

  const itemsSubmit = async (values) => {

    try {
      setAddItemLoader(true)
      if (values?.unitType?.toLowerCase() != "other") {
        values.otherUnit = ""
      }
      values.itemId = values?._id
      const res = selectItemId ? {} : await addVendorDprItemApi({ ...values, vendorId: dprFormik.values.vendorId, unitPrice: 0, totalQuantity: 0 })
      if (res.status === 200) {
        toast.success(res.data.message)

        if (selectItemId && dprFormik?.values?.items) {
          const editItemArr = dprFormik?.values?.items?.map(item => {

            if (item?.itemId == res.data?.data?._id) {
              return {
                ...item,
                unitType: item?.unitType?.toLowerCase() == "other" ? item?.otherUnit : item?.unitType,
                otherUnit: "",
                selected: true,
                itemDetails: res.data?.data
              }
            } else {
              return item
            }
          })
          // dprFormik.values.items = dprFormik?.values?.items?.filter(ele => { return (ele.itemId !== values._id) })
          dprFormik.setFieldValue("items", editItemArr)
        } else {
          itemSelectHandler(res.data?.data?.data)
        }
        setSelectItemId('')
        document.getElementById(addItemModalId).click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false)
  }

  const removeItem = async (vendorDprItemId) => {

    dprFormik.setFieldValue("items", [...dprFormik?.values?.items?.filter(ele => { return (ele.vendorDprItemId !== vendorDprItemId) })])
    document.getElementById(addItemModalId).click()
    try {
      const res = await deleteVendorDprItemByIdApi(vendorDprItemId, dprFormik.values.vendorId)
      if (res.status === 200) {
        toast.success(res.data?.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const itemFormik = useFormik({
    initialValues: addItemInitialValue,
    onSubmit: itemsSubmit,
    validationSchema: addItemValidationSchema
  })

  //vendor =================================================================

  const handleSelectDate = (e, eleType) => {
    if (eleType === 'dueDate') {
      dprFormik.setFieldValue("dueDate", new Date(e).toISOString())
    } else {
      dprFormik.setFieldValue("createDate", new Date(e).toISOString())
    }
  };

  const handleVendorSelect = (e) => {
    if (e.__isNew__) {
      VendorFormik.resetForm();
      VendorFormik.setFieldValue("name", e.value);
      dprFormik.resetForm();
      return document.getElementById("vendorCreate").click()
    } else {
      dprFormik.setFieldValue("vendorDetails", e.value)
      dprFormik.setFieldValue("vendorId", e.value?._id)
    }
  }


  const editVendorHandler = (vendorDetails) => {
    document.getElementById("vendorCreate").click()

    let vendorValue = {
      bankAccountNo: vendorDetails?.bankAccountNo,
      bankName: vendorDetails?.bankName,
      IFSC: vendorDetails?.IFSC,
      email: vendorDetails?.email,
      gstNumber: vendorDetails?.gstNumber,
      panNumber: vendorDetails?.panNumber,
      isActive: vendorDetails?.isActive,
      name: vendorDetails?.name,
      phone: vendorDetails?.phone,
      siteId: vendorDetails?.siteId,
      _id: vendorDetails?._id,
      address1: vendorDetails?.vendorAddressId?.address1,
      address2: vendorDetails?.vendorAddressId?.address2,
      city: vendorDetails?.vendorAddressId?.city,
      createdAt: vendorDetails?.vendorAddressId?.createdAt,
      isActive: vendorDetails?.vendorAddressId?.isActive,
      pinCode: vendorDetails?.vendorAddressId?.pinCode,
      state: vendorDetails?.vendorAddressId?.state,
      type: vendorDetails?.type,
      specialization: vendorDetails?.specialization,
      nickName: vendorDetails?.nickName,
      otherCity: ""
    }
    VendorFormik.setValues(vendorValue)
    VendorFormik.setFieldValue("siteId", undefined);

  }



  const filtervendors = async (inputValue, cb) => {
    try {
      const type = "Sub-Contractor"
      const res = await getvendorsApi(inputValue, "", "", type)
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.vendors?.map(ele => ({ label: ele.name, value: ele }))
        cb(fetchedItem);
      }
    } catch (error) {
      console.log(error)
    }
  };

  const vendorOnChange = debounce(filtervendors, 1000);

  const vendorOptions = (inputValue, callback) => {
    vendorOnChange(inputValue, callback);
  }


  const vendorSubmit = async (values) => {
    try {
      setAddItemLoader(true);
      if (values.city.toLowerCase() != "other") {
        values.otherCity = ""
      }
      const res = values?._id ? await updateVendorByIdApi({ ...values, siteId: userDetails?.siteId?._id }) : await createVendorApi({ ...values, siteId: userDetails?.siteId?._id });
      if (res.status === 200) {
        toast.success(res.data.message)
        const vendor = res.data?.data

        dprFormik.setFieldValue("vendorDetails", vendor)
        dprFormik.setFieldValue("type", vendor?.type)
        dprFormik.setFieldValue("vendorId", vendor?._id)
        document.getElementById(addVendorModalId).click()
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setAddItemLoader(false);
  }

  const VendorFormik = useFormik({
    initialValues: vendorAddInitailValue,
    onSubmit: vendorSubmit,
    validationSchema: vendorAddValidationSchema
  })


  // purchase orders =================================

  const checkHandler = (e, ele, type) => {
    let items = dprFormik.values.items.map((item, i) => {
      if (item.itemId === ele.itemId) {
        return {
          ...item,
          ...(type == "isDiameter" ? { isDiameter: e.target.checked } : { selected: e.target.checked }),
          // selected: e.target.checked,
        }
      } else {
        return item;
      }
    });

    dprFormik.setFieldValue("items", items)
  }


  const dprSubmit = async (values) => {
    let payload = JSON.parse(JSON.stringify(values));
    payload.items = payload.items.filter(item => item.selected)

    if (payload.items.length === 0) {
      return toast.error("Atleast select one item")
    }
 
    try {
      // const res = values?._id ? await updateVendorDprApi({...payload,isSaveDraft:isSaveDraft?.status}) : await createVendorDprApi({...payload,isSaveDraft:isSaveDraft?.status});
      setIsSaveDraft({status:false,index:null})
      const res =  await submitVendorDprApi({_id:values._id});
      if (res.status === 200) {
        toast.success(res.data.message);
        document.getElementById(submitConfirmModal).click()
        setConfirmationMsg(`DPR ${res.data?.data?.dprNumber || ""} is successfully ${poDetails?.status === "submitted" ? "updated." : (isSaveDraft?.status ? "Saved" : "submitted.")}`)
        dprFormik.setFieldValue("_id", res.data?.data?._id)
        document.getElementById('statusModalBtn').click()

      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
  }

  const dprSaveSubmit = async (value) => {
    let payload = JSON.parse(JSON.stringify(dprFormik?.values));
    payload.items = [value]

    try {
      const res =  await createVendorDprApi({...payload,isSaveDraft:true});
      if (res.status === 200) {
        toast.success(res.data.message);
        setIsSaveDraft({status:false,index:null})
        dprFormik.setFieldValue("_id", res.data?.data?._id)
        dprFormik.setFieldValue("dprNumber", res.data?.data?.dprNumber)
        dprFormik.setFieldValue("status", res.data?.data?.status)
        console.log("saving",res.data?.data);
        
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
  }

  const dprFormik = useFormik({
    initialValues: dprAddInitalValue,
    onSubmit: dprSubmit,
    validationSchema: dprAddValidationSchema
  })

  const getVendorDprById = async (id) => {
    const res = await getDprByIdApi(id)
    if (res.status === 200) {
      const podata = res.data?.data
      dprFormik.setFieldValue("_id", podata?._id)
      dprFormik.setFieldValue("vendorDetails", podata.vendor)
      dprFormik.setFieldValue("vendorId", podata.vendor._id)
      dprFormik.setFieldValue("type", podata.vendor.type)
      dprFormik.setFieldValue("createDate", podata?.createDate)
      dprFormik.setFieldValue("remarks", podata?.remarks || '')
      setSelectedItems(podata?.items)
      // setTimeout(() => {
      getItems(podata?.items, podata.vendor._id);
      // }, 100)
      setPoDetails(podata)
    }
  }

  const getItems = async (items = [], id) => {
    try {
      const res = await getVendorDprItemsApi("", "", "", id ? id : dprFormik.values.vendorId);
      if (res.status === 200) {
        let fetchedItem = res.data?.data?.data
        // console.log("fetched item", fetchedItem, items);

        let selected = fetchedItem?.map(item => {
          if (items.filter(el => el.itemId === item.itemId).length > 0) {

            let elem = items?.filter(el => el.itemId === item.itemId)[0]
            // console.log(elem)
            return {
              selected: true,
              ...elem
            }
          } else {
            return {
              selected: false,
              itemId: item?.itemDetails?._id,
              vendorDprItemId: item?._id,
              quantity: '',
              unitPrice: item?.unitPrice,
              workDescription: "",
              commoditySubPart:"",
              commodityPart:"",
              // length: 0,
              // breath: 0,
              // height: 1,
              // number1: 1,
              // number2: 1,
              // diameter: 0,
              isDiameter: false,
              itemDetails: item?.itemDetails,
              itemQty: [
                {
                  length: 0,
                  breath: 0,
                  height: 1,
                  number1: 1,
                  number2: 1,
                  diameter: 0,
                  remarks: "",
                  subPart: "",
                }
              ]
            }
          }
        })

        dprFormik.setFieldValue("items", selected)
      }
    } catch (error) {

    }
  }


  useEffect(() => {
    if (dprId) {
      getVendorDprById(dprId)
    }

  }, [])




  useEffect(() => {
    if (dprFormik.values.vendorId) {
      getItems(dprId ? selectedItems : [], dprFormik.values.vendorId)
    }
  }, [dprFormik.values.vendorId])



  const handleConfirmation = (isConvert) => {
    isSaveDraft.status ? dprSaveSubmit() : dprFormik.handleSubmit();
  }

  const checkValidation = (btn) => {
    const errors = Object.keys(dprFormik.errors)   
    if (errors?.length > 0) {
      dprFormik.handleSubmit()
    } else {
      document.getElementById(btn).click()
    }
  }

  const checkSaveValidation = (btn,index) => {
    const errors = Object.keys(dprFormik.errors)
    const value = dprFormik?.values?.items?.filter((ele,ind)=>index==ind)?.[0]
    if (errors?.length > 0) {
      if(dprFormik?.errors?.items?.filter((ele,ind)=>index==ind)?.[0]){       
        dprFormik.handleSubmit()
      }else{
        if(!value){
          toast.error("Please select item")
        }else{
          // document.getElementById(btn).click()
          dprSaveSubmit(value)
          setIsSaveDraft({status:true,index:index})
        } 
      }   
    } else {
      if(!value){
        toast.error("Please select item")
      }else{
        // document.getElementById(btn).click()
        dprSaveSubmit(value)
        setIsSaveDraft({status:true,index:index})
      } 
    } 
  }

  const handleApprovalModalClose = () => {
    !isSaveDraft.status && navigate("/dpr")
  }

  const handleConvertModalClose = () => {
    if (PoEditId) {
      navigate(`/challan/Edit/${PoEditId}`);
    }
  }

  const vendorTypeOptions = [
    { label: "Vendor", value: "Vendor" },
    { label: "Sub-Contractor", value: "Sub-Contractor" },
  ]

  const handleVendorType = (e) => {
    dprFormik.setFieldValue("type", e?.value)
    dprFormik.setFieldValue("vendorId", "")
    dprFormik.setFieldValue("vendorDetails", {})
    setReLoadVendor(true)
  }

  useEffect(() => {
    if (reLoadVendor) {
      setTimeout(() => {
        setReLoadVendor(false)
      }, 200);
    }
  }, [reLoadVendor])

  const getItemVolumeQty = (e, values) => {
    if (e?.selected && values) {
      const {isDiameter} = e
      const { number1, number2, length, breath, height, diameter } = values
      if (isDiameter) {
        return Number((Number(number1 || 0) * Number(number2 || 0) * Math.PI * Number(diameter || 0) * Number(diameter || 0) * Number(height || 0)) / 4).toFixed(2)
      } else {
        return Number(Number(number1 || 0) * Number(number2 || 0) * Number(length || 0) * Number(breath || 0) * Number(height || 0)).toFixed(2)
      }
    }
    return 0
  }

  const getVolumeQty = (e) => {
    if (e?.selected) {
      const {isDiameter,itemQty} = e
      let totalQty = 0;
      itemQty?.forEach(ele=>{
        // if(itemQty){}
        const { number1, number2, length, breath, height, diameter } = ele
        if (isDiameter) {
          totalQty+= Number(Number((Number(number1 || 0) * Number(number2 || 0) * Math.PI * Number(diameter || 0) * Number(diameter || 0) * Number(height || 0)) / 4).toFixed(2))
        } else {
          totalQty+= Number(Number(Number(number1 || 0) * Number(number2 || 0) * Number(length || 0) * Number(breath || 0) * Number(height || 0)).toFixed(2))
        }
      })
      return Number(totalQty).toFixed(2)
    }
    return 0
  }


const handleAddItemQty =(itemIndex, qtyIndex)=>{
  let newItemQty = {
    length: 0,
    breath: 0,
    height: 1,
    number1: 1,
    number2: 1,
    diameter: 0,
    remarks: "",
    subPart: "",
  }

   let newList = dprFormik.values.items.map((item,ind)=>{
    if(itemIndex==ind){
      let updatedItemQty = []
      item?.itemQty?.forEach((ele,index)=>{
        if(qtyIndex==index){
          updatedItemQty.push(ele,newItemQty)
        }else{
          updatedItemQty.push(ele)
        }
      })
      return {
        ...item,
        itemQty:updatedItemQty
      }
    }else{
      return item
    }
   })
   dprFormik.setFieldValue("items",newList)
}


const handleRemoveItemQty =(itemIndex, qtyIndex)=>{
  let newList = dprFormik.values.items.map((item,ind)=>{
    if(itemIndex==ind){
      return {
        ...item,
        itemQty:item?.itemQty?.filter((ele,index)=>qtyIndex!=index)
      }
    }else{
      return item
    }
   }) 
   dprFormik.setFieldValue("items",newList)
}



  return (
    <>
      <div className="dash-nav1">
        <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> Back</span></div>
        <div className="align-items-center d-block d-lg-flex justify-content-between">
          <h3 className="mb-0 font-blue">{dprId ? "Edit" : "Create New"} DPR</h3>
          {dprFormik.values.vendorId && <div>            
            <button type="button" disabled={addLoader} className="mb-2 mb-sm-2 sbm-purchase" onClick={() => {
              if (dprFormik.values?.items.filter(item => item.selected)?.length) {
                checkValidation("submitModalBtn1")
                setIsSaveDraft({status:false,index:null})
              } else toast.error("Please select items")
            }}>
              {(addLoader && !isSaveDraft) ? <Spinwheel sizeClass={"spin-small"} /> : "Submit for approval"}
            </button>


          </div>}
        </div>
      </div>
      <div className="dash-table">
        <div className="container">
          <div className="row gap-3 gap-xl-0">
            <div className="col-12 col-xl-3">
              <div className="vendor-detail ">
                <div className='d-flex align-items-center justify-content-between bb'>
                  <h6 className='mb-0 pb-0' style={{ border: "none" }}>Sub-contractor details</h6>
                  {/* <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addVendorModalId}`} onClick={() => VendorFormik.resetForm()}>
                  <i className="bi bi-plus"></i> Add
                </button> */}
                </div>
                <form action="">
                  <div className="form-details">
                    <label htmlFor="">Name</label>
                    <div className='d-flex align-items-center justify-content-center mb-3 '>
                      <div className='cursor-pointer w-100' >
                        <AsyncSelect style={{ cursor: "pointer !important" }} isDisabled={dprId || dprFormik?.values?._id} className='col w-100' placeholder="Select vendor..." cacheOptions value={{ label: dprFormik.values?.vendorDetails?.name, value: dprFormik.values?.vendorDetails }} loadOptions={vendorOptions} defaultOptions onChange={(e) => { handleVendorSelect(e) }} />
                      </div>
                      {/* <i className="form-input bi bi-plus-square ms-2" style={{cursor:"pointer"}} data-bs-toggle="modal" data-bs-target={`#${addVendorModalId}`}></i> */}
                    </div>
                    {dprFormik?.touched?.vendorId && dprFormik?.errors?.vendorId ? (<div className='req-error'>{dprFormik?.errors?.vendorId}</div>) : null}
                  </div>

                  {dprFormik.values.vendorId && <>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-12">
                          <label htmlFor="startDate">Date</label>
                          <DatePicker dateFormat="dd/MM/yyyy" placeholderText='Create date' className='form-select cursor-pointer' name='createDate' selected={dprFormik.values?.createDate} onChange={(e) => { handleSelectDate(e, "createDate") }} maxDate={new Date(dprFormik.values?.dueDate)} />
                          <span id="startDateSelected"></span>

                        </div>
                      </div>
                    </div>
                    <div className="form-details">
                      <label htmlFor="">GST number</label>
                      <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={dprFormik.values?.vendorDetails?.gstNumber} />
                    </div>
                    {dprFormik?.values?.type != "Vendor" &&
                      <div className="form-details">
                        <label htmlFor="">PAN number</label>
                        <input type="text" className="form-control" id="GST1" placeholder="Enter your GST number" disabled value={dprFormik.values?.vendorDetails?.panNumber} />
                      </div>}

                    <h6 className="mt-4">Address</h6>
                    <div className="form-details">
                      <label htmlFor="">Flat, House no., Building, Company, Apartment</label>
                      <input type="text" className="form-control" disabled value={dprFormik.values?.vendorDetails?.vendorAddressId?.address1} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">Area, Street, Sector, Village</label>
                      <input className="w-100 form-control" type="text" placeholder="Enter area..." disabled value={dprFormik.values?.vendorDetails?.vendorAddressId?.address2} />
                    </div>
                    <div className="form-details">
                      <label htmlFor="">State</label>
                      <select className="form-control" aria-label="Small select example" disabled value={dprFormik.values?.vendorDetails?.vendorAddressId?.state}>
                        <option value={dprFormik.values?.vendorDetails?.vendorAddressId?.state}>{dprFormik.values?.vendorDetails?.vendorAddressId?.state}</option>
                      </select>
                    </div>
                    <div className="form-details">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="startDate">City</label>
                          <select className="form-control" disabled aria-label="Small select example" value={dprFormik.values?.vendorDetails?.vendorAddressId?.city}>
                            <option value={dprFormik.values?.vendorDetails?.vendorAddressId?.city}>{dprFormik.values?.vendorDetails?.vendorAddressId?.city}</option>
                          </select>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="endDate">Pincode</label>
                          <input className="form-control" type="text" placeholder="Enter pin" disabled value={dprFormik.values?.vendorDetails?.vendorAddressId?.pinCode} />
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>}
                </form>
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <div className="items-detail-box position-relative ">
                <div className='d-flex justify-content-between align-items-center bb'>
                  <h6 className='mb-0 pb-0' style={{ border: "none" }}>Items details</h6>
                  {dprFormik.values.vendorId && <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.resetForm(); setSelectItemId("") }}>
                    <i className="bi bi-plus"></i> Add Item
                  </button>}
                </div>

                {dprFormik.values?.vendorId && (
                  <>
                    {dprFormik?.values?.items?.map((ele, index) => (
                      <div className='mb-3' key={index + "sdkjfdkj"}>
                        {/* First Row: Basic Details */}
                        <div className='d-flex justify-content-between'>
                          <div >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={dprFormik?.values?.items?.[index]?.selected}
                              name={`items[${index}].selected`}
                              onChange={(e) => checkHandler(e, ele)}
                            />
                            <span className='fw-bold'>
                              {ele?.itemDetails?.name}
                            </span>
                          </div>

                          <div >
                            <i
                              className="bi bi-trash3 point"
                              data-bs-toggle="modal"
                              data-bs-target={`#${addItemModalId}`}
                              onClick={() => {
                                itemFormik.setValues({
                                  ...ele?.itemDetails,
                                  isDisabled: true,
                                });
                                itemFormik.setFieldValue(
                                  "totalQuantity",
                                  dprFormik?.values?.items?.[index]?.quantity
                                );
                                setSelectItemId(ele?.vendorDprItemId);
                              }}
                            ></i>
                          </div>
                        </div>
                        {/* Second Row: Description */}
                        <div>
                          <div className='d-flex justify-content-between'>
                          <div className='d-flex gap-2'>
                            <label htmlFor='isDiameter'>Description:- </label>
                            {ele?.itemDetails?.description}</div>
                          <div className='d-flex gap-2'>
                            <label htmlFor='isDiameter'>Unit:- </label>
                            {ele?.itemDetails?.unitType}</div>
                          </div>
                      
                            <div className='d-flex align-items-center gap-2 w-100'>
                          <div className='w-100'>
                            <label htmlFor='commodityPart'>Commodity Part <span className='req-error'>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              name={`items[${index}].commodityPart`}
                              value={dprFormik?.values?.items?.[index]?.commodityPart}
                              onChange={dprFormik.handleChange}
                              onBlur={dprFormik.handleBlur}
                              disabled={!dprFormik?.values?.items?.[index]?.selected}
                            />
                            {dprFormik?.touched?.items?.[index]?.commodityPart && dprFormik?.errors?.items?.[index]?.commodityPart ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.commodityPart}</div>) : null}
                          </div>
                          <div className='w-100'>
                            <label htmlFor='commoditySubPart'>Commodity Sub Part <span className='req-error'>*</span></label>
                            <input
                              type="text"
                              className="form-control"
                              name={`items[${index}].commoditySubPart`}
                              value={dprFormik?.values?.items?.[index]?.commoditySubPart}
                              onChange={dprFormik.handleChange}
                              onBlur={dprFormik.handleBlur}
                              disabled={!dprFormik?.values?.items?.[index]?.selected}
                            />
                            {dprFormik?.touched?.items?.[index]?.commoditySubPart && dprFormik?.errors?.items?.[index]?.commoditySubPart ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.commoditySubPart}</div>) : null}
                          </div>
                          <div className='w-100 mt-1'>
                            <label htmlFor='workDescription'>Work Description<span className='req-error'>*</span></label>
                            <input
                              className="form-control w-100"
                              name={`items[${index}].workDescription`}
                              value={dprFormik?.values?.items?.[index]?.workDescription}
                              onChange={dprFormik.handleChange}
                              onBlur={dprFormik.handleBlur}
                              disabled={!dprFormik?.values?.items?.[index]?.selected}
                            />
                            {dprFormik?.touched?.items?.[index]?.workDescription &&
                              dprFormik?.errors?.items?.[index]?.workDescription ? (
                              <div className="req-error">
                                {dprFormik?.errors?.items?.[index]?.workDescription}
                              </div>
                            ) : null}
                          </div>
                            </div>
                          <div >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={dprFormik?.values?.items?.[index]?.isDiameter}
                              name={`items[${index}].isDiameter`}
                              onChange={(e) => checkHandler(e, ele, "isDiameter")}
                              disabled={!dprFormik?.values?.items?.[index]?.selected}
                            />
                            <label htmlFor='isDiameter'>Diameter required</label>
                          </div>
                        </div>

                        {/* Third Row: Dimensions */}     
                        <div className='w-100 mt-1'>
                          <div className="table-responsive" style={{ overflowX: "auto" }}>
                            <table className="table table-bordered" style={{ minWidth: "200px" }}>
                              <thead>
                                <tr>
                                  <th  style={{ minWidth: "100px" }}>Sub Part</th>
                                  <th>No1</th>
                                  <th>No2</th>
                                  {!dprFormik?.values?.items?.[index]?.isDiameter && <>
                                    <th>L</th>
                                    <th>B</th>
                                  </> }
                                  <th>H</th>
                                 {dprFormik?.values?.items?.[index]?.isDiameter &&   <th>D</th>}
                                  <th>QTY</th>
                                  <th  style={{ minWidth: "100px" }}>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ele?.itemQty?.map((qty, qtyIndex) => (
                                  <tr key={qtyIndex} style={{height:"50px"}}>
                                    <td style={{width:"150px !important"}}>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].itemQty[${qtyIndex}].subPart`}
                                        value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.subPart}
                                        onChange={dprFormik.handleChange}
                                        onBlur={dprFormik.handleBlur}
                                        disabled={!dprFormik?.values?.items?.[index]?.selected}
                                        style={{height:"30px"}}
                                        />
                                      {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.subPart && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.subPart ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.subPart}</div>) : null}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].itemQty[${qtyIndex}].number1`}
                                        value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.number1}
                                        onChange={dprFormik.handleChange}
                                        onBlur={dprFormik.handleBlur}
                                        disabled={!dprFormik?.values?.items?.[index]?.selected}
                                        style={{height:"30px"}}
                                      />
                                      {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.number1 && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.number1 ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.number1}</div>) : null}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].itemQty[${qtyIndex}].number2`}
                                        value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.number2}
                                        onChange={dprFormik.handleChange}
                                        onBlur={dprFormik.handleBlur}
                                        disabled={!dprFormik?.values?.items?.[index]?.selected}
                                        style={{height:"30px"}}
                                      />
                                      {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.number2 && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.number2 ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.number2}</div>) : null}
                                    </td>
                                    {!dprFormik?.values?.items?.[index]?.isDiameter &&<>
                                     <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].itemQty[${qtyIndex}].length`}
                                        value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.length}
                                        onChange={dprFormik.handleChange}
                                        onBlur={dprFormik.handleBlur}
                                        disabled={!dprFormik?.values?.items?.[index]?.selected}
                                        style={{height:"30px"}}
                                      />
                                      {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.length && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.length ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.length}</div>) : null}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].itemQty[${qtyIndex}].breath`}
                                        value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.breath}
                                        onChange={dprFormik.handleChange}
                                        onBlur={dprFormik.handleBlur}
                                        disabled={!dprFormik?.values?.items?.[index]?.selected}
                                        style={{height:"30px"}}
                                      />
                                      {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.breath && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.breath ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.breath}</div>) : null}
                                    </td>
                                    </>}
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].itemQty[${qtyIndex}].height`}
                                        value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.height}
                                        onChange={dprFormik.handleChange}
                                        onBlur={dprFormik.handleBlur}
                                        disabled={!dprFormik?.values?.items?.[index]?.selected}
                                        style={{height:"30px"}}
                                      />
                                      {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.height && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.height ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.height}</div>) : null}
                                    </td>
                               
                                    {dprFormik?.values?.items?.[index]?.isDiameter && (
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`items[${index}].itemQty[${qtyIndex}].diameter`}
                                          value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.diameter}
                                          onChange={dprFormik.handleChange}
                                          onBlur={dprFormik.handleBlur}
                                          disabled={!dprFormik?.values?.items?.[index]?.selected}
                                          style={{height:"30px"}}
                                        />
                                        {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.diameter && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.diameter ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.diameter}</div>) : null}
                                      </td>
                                    )}
                                    <td>{getItemVolumeQty(dprFormik?.values?.items?.[index],dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex])}</td>
                                    <td style={{width:"450px !important"}} className='d-flex gap-2'>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name={`items[${index}].itemQty[${qtyIndex}].remarks`}
                                        value={dprFormik?.values?.items?.[index]?.itemQty?.[qtyIndex]?.remarks}
                                        onChange={dprFormik.handleChange}
                                        onBlur={dprFormik.handleBlur}
                                        disabled={!dprFormik?.values?.items?.[index]?.selected}
                                        style={{height:"30px"}}
                                      />
                                      {dprFormik?.touched?.items?.[index]?.itemQty?.[qtyIndex]?.remarks && dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.remarks ? (<div className='req-error'>{dprFormik?.errors?.items?.[index]?.itemQty?.[qtyIndex]?.remarks}</div>) : null}
                                
                                    </td>
                                    <td>
                                      <div className='d-flex gap-2'>
                                      {dprFormik?.values?.items?.[index]?.itemQty.length>1 && <button className='add-vendor'  disabled={!dprFormik?.values?.items?.[index]?.selected} onClick={()=>handleRemoveItemQty(index,qtyIndex)}>-</button>}
                                      {<button className='add-vendor'  disabled={!dprFormik?.values?.items?.[index]?.selected} onClick={()=>handleAddItemQty(index,qtyIndex)}>+</button>}
                                      </div>
                                    </td>
                                
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex gap-2'>
                            <label htmlFor='isDiameter'>Total Quantity:- </label>
                            {getVolumeQty(dprFormik?.values?.items?.[index])}</div>
                          <div>
                            {dprFormik?.values?.items?.[index]?.selected && <button type="button" disabled={(addLoader || isSaveDraft?.status)} className="add-vendor mb-sm-0 mb-sm-2 px-2" onClick={() => {
                            if (dprFormik.values?.items.filter(item => item.selected)?.length) {
                              checkSaveValidation("submitModalBtn1",index)
                            } else toast.error("Please select items")
                          }}>
                            {(addLoader || (isSaveDraft?.status && isSaveDraft?.index==index)) ? <Spinwheel sizeClass={"spin-small"} /> : "Save"}
                          </button>}

                          </div>
                        </div>

                        <hr />
                      </div>
                    ))}
                    <div className='d-flex justify-content-end'>
                    {dprFormik.values.vendorId && <button className="add-vendor mb-sm-0 mb-sm-2" data-bs-toggle="modal" data-bs-target={`#${addItemModalId}`} onClick={() => { itemFormik.resetForm(); setSelectItemId("") }}>
                    <i className="bi bi-plus"></i> Add Item
                  </button>}
                    </div>
                  </>
                )}

                <hr />
                {/* <div className="form-details">
                  <label htmlFor="remarks" className="form-label">Remarks</label>
                  <textarea className="w-100 form-control" type="text" name={`remarks`} placeholder="Enter note..." disabled="" onChange={dprFormik.handleChange} value={dprFormik?.values?.remarks} />
                  {dprFormik?.touched?.remarks && dprFormik?.errors?.remarks ? (<div className='req-error'>{dprFormik?.errors?.remarks}</div>) : null}
                </div> */}

              </div>
            </div>
            <div className="col-12 col-xl-3">
              <div className="order-review">
                <div className="d-flex bb justify-content-between align-items-center">
                  <h6>Preview</h6>

                </div>
                {dprFormik.values.vendorId && <div className="org">
                  <div className="d-flex  justify-content-between align-items-center">
                    <h4 className="mb-3">{dprFormik.values?.vendorDetails?.name}</h4>
                    {/* {dprFormik.values.vendorId && <div onClick={() => { editVendorHandler(dprFormik.values.vendorDetails) }} className='cursor-pointer'><i className="bi bi-pencil-square"></i></div>} */}
                  </div>

                  {poDetails?.dprNumber && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">DPR number</p>
                    <p className="light">{poDetails?.dprNumber}</p>
                  </div>}

                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">Date</p>
                    <p className="light">{moment(dprFormik.values?.createDate).format("DD/MM/YYYY")}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">{dprFormik.values?.vendorDetails?.type == "Vendor" ? "GST number" : "PAN number"}</p>
                    <p className="light">{dprFormik.values?.vendorDetails?.type == "Vendor" ? dprFormik.values?.vendorDetails?.gstNumber : dprFormik.values?.vendorDetails?.panNumber}</p>
                  </div>
                  {poDetails?.status && <div className="d-flex justify-content-between align-items-center">
                    <p className="dark">Status</p>
                    <p className="light">{poDetails?.status}</p>
                  </div>}
                  <div className="add-box">
                    <h5>Address:</h5>
                    {dprFormik.values.vendorId && <p className="mb-0">{dprFormik.values?.vendorDetails?.vendorAddressId?.address1}, {dprFormik.values?.vendorDetails?.vendorAddressId?.address2}, {dprFormik.values?.vendorDetails?.vendorAddressId?.city}, {dprFormik.values?.vendorDetails?.vendorAddressId?.state}, {dprFormik.values?.vendorDetails?.vendorAddressId?.pinCode}</p>}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6>Items (count: {dprFormik?.values?.items?.filter(item => item?.selected)?.length})</h6>
                    {/* <i className="bi bi-pencil-square"></i> */}
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }} scope="col">NAME</th>
                        <th scope="col">QTY</th>
                        <th scope="col">UNIT</th>
                      </tr>
                    </thead>
                    <tbody>

                      {dprFormik.values?.vendorId && <>
                        {dprFormik?.values?.items?.filter(item => item?.selected)?.map((ele, index) => {
                          // console.log(ele)
                          return (<tr>
                            <td style={{ overflowWrap: "anywhere" }}>{ele?.itemDetails?.name}</td>
                            <td>
                            {getVolumeQty(ele)}
                            </td>
                            <td>
                              {ele?.itemDetails?.unitType}
                            </td>
                          </tr>)
                        })
                        }
                      </>}
                    </tbody>
                  </table>
                </div>}
                {dprFormik.values.remarks && <div className="add-box">
                  <h5>Remarks:</h5>
                  <p className="mb-0">{dprFormik.values?.remarks}</p>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {dprFormik.values.vendorId &&
        <ItemsAddModal formik={itemFormik} modalId={addItemModalId} addLoader={addItemLoader} type="dpr" removeItem={removeItem} selectItem={selectItemId} vendorId={dprFormik?.values?.vendorId} />
      }
      <VendorAddModal formik={VendorFormik} modalId={addVendorModalId} addLoader={addItemLoader} />
      <div className="col">
        <div className="btn btn-primary hidden" style={{ display: "none" }} data-bs-toggle="modal" id='vendorCreate' data-bs-target={`#${addVendorModalId}`} onClick={() => { itemFormik.resetForm() }} >Create Vendor</div>
      </div>
      <ConfirmationModal modalId={ItemDeleteModalId} handleConfirm={handleItemDelete} message={<>Are you sure you want to <br /> Delete ?</>} />
      {/* for po approval */}
      <button className='d-none' id="submitModalBtn1" data-bs-toggle="modal" data-bs-target={`#${submitConfirmModal}`} />
      <ConfirmationModal modalId={submitConfirmModal} handleConfirm={() => handleConfirmation(false)} message={<>Are you sure you want to <br /> {isSaveDraft ? "Save" : "Submit"} ?</>} />
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleApprovalModalClose}
      />

      {/* for po convert to purchase */}
      <button className='d-none' id="convertModalBtn" data-bs-toggle="modal" data-bs-target={`#${convertConfirmModal}`} />
      <ConfirmationModal modalId={convertConfirmModal} handleConfirm={() => handleConfirmation(true)} message={<>Are you sure you want to submit and <br /> convert to Purchase order?</>} />

    </>
  )
}

export default AddDailyProgressReport