import React, { useState } from 'react'
import { addDocumentApi, fileUploadApi } from '../../utils/ApiEndpointFunctions';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { S3_URL } from '../../utils/BaseUrl';
import { uploadInitialValue, uploadValidationSchema } from '../../utils/Validation';
import { useNavigate } from 'react-router-dom';
import Spinwheel from '../../components/common/Spinwheel';
import { FILE_ACCEPT, INVALID_FILE_FORMAT_ERROR_MESSAGE } from '../../utils/constant';
import { getFileOriginalName } from '../../utils/HelperFuncitons';

function AddOrUpdateUpload() {
  const navigate = useNavigate();
  const [submitLoader, setSubmitLoader] = useState(false);

  const fileUploadHandler = async (e, index) => {
    try {

      const file1 = e.target.files[0];

      const file = new File([file1], `${new Date().getTime()}_${file1.name}`, {
        type: file1.type,
        lastModified: file1.lastModified,
      });

      if (file) {
        const allowedExtensions = ['pdf', 'doc', 'docx', 'xlsx', 'png', 'jpeg','jpg'];

        const isValidFileType = (fileName) => {
          const fileExtension = fileName.split('.').pop().toLowerCase();
          return allowedExtensions.includes(fileExtension);
        };

        // Usage
        if (!isValidFileType(file1.name)) {
          toast.error("Unsupported file type. Please upload a valid file.");
          return;
        }


        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "document")

        const res = await fileUploadApi(formData)
        if (res.status === 200 && res.data?.status === 1) {
          toast.success("File uploaded");
          uploadFormik.setFieldValue(`documents[${index}].file`, res.data?.data)

        }
      }
    } catch (error) {
      console.log(error)
      if (error?.response === undefined) {
        toast.error("Failed to uploading your file. Please try again later.")
      }
    }

  }

  const addHandler = () => {
    uploadFormik.setFieldValue("documents", [...uploadFormik.values.documents, { file: "", name: "", description: "" }])
  }

  const removeHandler = (index) => {
    uploadFormik.setFieldValue("documents", [...uploadFormik.values.documents.filter((ele, i) => i !== index)])
  }

  const uploadSubmit = async (values) => {

    try {
      setSubmitLoader(true)
      const res = await addDocumentApi(values);
      if (res.status === 200 && res?.data?.status === 1) {
        toast.success(res.data.message)
        navigate(`/upload`)
      }else{
        toast.error(res?.data?.messages);
      }
    } catch (error) {
      error && error.response ?
        toast.error(error.response?.data?.message) :
        toast.error("something went wrong");
    }
    setSubmitLoader(false)
  }

  const uploadFormik = useFormik({
    initialValues: uploadInitialValue,
    onSubmit: uploadSubmit,
    validationSchema: uploadValidationSchema
  });


  return (
    <>
      <div className="dash-nav1">
        <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> Back</span></div>

        <div className="d-flex justify-content-between align-items-center mb-3">
          <h3 className="mb-0 font-blue">Upload Document</h3>

          {uploadFormik?.values?.documents?.length < 5 && <div><button className="plus-minus-icon" style={{ padding: "" }} onClick={addHandler}><i className="bi bi-plus-lg"></i></button></div>}
        </div>

        {uploadFormik.values.documents.map((ele, index) => {

          return <div className="card mt-1" key={index}>
            <div className="row mt-3">
              <div className="col-3 d-flex align-items-center justify-content-center">
                <div className=' d-flex justify-content-center align-items-center flex-column'>

                  <div className=' border border-2 btn_upload d-flex align-items-center p-0' style={{ backgroundColor: "#0B60B0", color: "#fff", width: "80px", hight: "50px" }}  >
                    <label htmlFor={`upload_btn_file${index}`} style={{ cursor: "pointer" }} className='h-100 w-100'>
                      <input type="file" className='btn btn_upload_file d-none ' id={`upload_btn_file${index}`} onChange={(e) => { fileUploadHandler(e, index) }} 
                      />
                      <span className="bi bi-paperclip m-auto" ></span>
                    </label>
                  </div>

                  <p>
                    {uploadFormik?.values?.documents?.[index]?.file ?
                      <div className='text-center p-2' style={{ overflowWrap: "anywhere" }}>{getFileOriginalName(uploadFormik?.values?.documents?.[index]?.file?.split("/")[uploadFormik?.values?.documents?.[index]?.file?.split("/")?.length -1])}</div> :
                      <> {uploadFormik?.touched?.documents?.[index]?.file && uploadFormik?.errors?.documents?.[index]?.file ? (<div className='req-error'>{uploadFormik?.errors?.documents?.[index]?.file}</div>) : null}</>}
                  </p>
                </div>

              </div>
              <div className="col-8">
                <div className="form-details"><label htmlFor="">Name <span className='req-error'>*</span></label>
                  <input className="w-100 form-control" type="text" name={`documents[${index}].name`} placeholder="Enter name..." disabled="" value={uploadFormik?.values?.documents?.[index]?.name} onChange={uploadFormik.handleChange} onBlur={uploadFormik.handleBlur} />
                  {uploadFormik?.touched?.documents?.[index]?.name && uploadFormik?.errors?.documents?.[index]?.name ? (<div className='req-error'>{uploadFormik?.errors?.documents?.[index]?.name}</div>) : null}
                </div>
                {/* <hr /> */}
                <div className="form-details"><label htmlFor="">Description</label>
                  <textarea className="w-100 form-control" type="text" name={`documents[${index}].description`} placeholder="Enter desc..." disabled="" onChange={uploadFormik.handleChange} />
                </div>
              </div>
              {uploadFormik?.values?.documents?.length > 1 && <div className="col-1 d-flex justify-content-end align-items-end">
                <div> <button className="plus-minus-icon text-secondary border-secondary me-1 mb-1" onClick={() => { removeHandler(index) }} ><i className="bi bi-dash-lg"></i></button></div>
              </div>}
            </div>
          </div>
        })}

        <div className='mt-3'>
          <button className="create-order" disabled={submitLoader} onClick={uploadFormik?.handleSubmit} >
            {submitLoader ? <Spinwheel sizeClass={"spin-small"} /> : "Save"}
          </button>
        </div>
      </div>
    </>
  )
}

export default AddOrUpdateUpload