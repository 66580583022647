import React, { useEffect, useState } from 'react'
import Spinwheel from '../../components/common/Spinwheel';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import { approveOrRejectApi, getCashbooksApi, getInventoryReportApi } from '../../utils/ApiEndpointFunctions';
import ReactPaginate from 'react-paginate';
import { debounce } from 'lodash';
import { S3_URL } from '../../utils/BaseUrl';
import { useFormik } from 'formik';
import VoucherFilter from '../../components/modal/VoucherFilter';
import CashbookFilter from '../../components/modal/CashbookFilter';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import DatePicker from "react-datepicker";
import { formatPrice } from '../../utils/HelperFuncitons';
import { useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx-js-style';

function InventoryReport(props) {
   const location = useLocation();
   const [cashbook, setCashbook] = useState([])
   const [loading, setLoading] = useState(false)
   const [totalCount, setTotalCount] = useState(null);
   const [page, setPage] = useState(0);
   let [selectedPage, setSelectedPage] = useState(1);
   const navigate = useNavigate();
   const limit = 10;
   const confirmModalId = "conFirmModalID"
   const statusModalId = "statusModal"
   const cashbookFilterModal = "cashbookFilterModalId"
   const { userDetails } = useUserContext();
   const [setectedId, setSelectedId] = useState();
   const [voucherStatus, setVoucherStatus] = useState();
   const [searchParams, setSearchParams] = useSearchParams();
   const [isSearch,setIsSearch] = useState(false)
   const [siteBalance, setSiteBalance] = useState(0);
   const [cashbookFieldError, setCashBookFieldError] = useState(false)
   const [download, setDownload] = useState(false)
   const {id} = useParams();


   const getInventoryReportDetails = async () => {
      if(id){
         setLoading(true);
         try {
            const page = searchParams.get("page") || 1
            const res = await getInventoryReportApi(id,"", limit, page);
            if (res.status === 200) {
   
               setCashbook(res.data?.data?.data);
               setTotalCount(res.data?.data?.data?.totalCount);
               setPage(Math.ceil(res.data?.data?.data?.totalCount / limit));
               setIsSearch(true)
               setCashBookFieldError(false)
            }
         } catch (error) {
            console.log(error);
         } finally {
            setLoading(false);
            setIsSearch(true)
         }
      }
   }

   const handleFilterCashbook = () => {
      if (searchParams.get('page') == 1) {
         getInventoryReportDetails()
      } else {
         setSearchParams({ "page": 1 })
      }
   }

   const handleVoucherApproval = async () => {
      try {
         const res = await approveOrRejectApi({ id: setectedId, status: voucherStatus })
         if (res.status === 200) {
            toast.success(res.data?.message)
            document.getElementById(confirmModalId).click()
            document.getElementById("statusModalBtn").click()
            getInventoryReportDetails()
         }
      } catch (error) {
         console.log(error)
         toast.error("something went wrong");
      }
   }


   const handlePageClick = (event) => {
      let currentPage = event.selected + 1;

      const newParams = new URLSearchParams(searchParams);
      newParams.set("page", currentPage);

      if (newParams.has("tab")) {
         newParams.set("tab", newParams.get("tab"));
      }

      setSearchParams(newParams);
   };

   const filterFormik = useFormik({
      initialValues: {
         vendorType: "", name: "", challanNumber: "", voucherType: "", expenseType: "", to: "", from: ""
      }
   })

   useEffect(() => {
         getInventoryReportDetails()
   }, [searchParams.get('page')])


   useEffect(()=>{
      if (location?.state?.path == "/cashbook" && location.state?.filterValue){
         filterFormik.values.to = location.state?.filterValue?.to
         filterFormik.values.from = location.state?.filterValue?.from
         filterFormik?.setFieldValue("to", location.state?.filterValue?.to)
         filterFormik?.setFieldValue("from", location.state?.filterValue?.from)
         setIsSearch(true)
         getInventoryReportDetails()
      }
   },[location])


   // const exportToExcel = async () => {
   //    if (filterFormik?.values?.to && filterFormik?.values?.from) {
   //       try {
   //          setDownload(true);
   //          let filter = [
   //             filterFormik.values?.vendorType,
   //             filterFormik.values?.name,
   //             filterFormik.values?.challanNumber,
   //             filterFormik.values?.voucherType,
   //             filterFormik.values?.expenseType,
   //             `${filterFormik.values?.to ? new Date(filterFormik.values?.to).getTime() + "-" : ""}${filterFormik.values?.from ? new Date(filterFormik.values?.from).getTime() : ""}`
   //          ];
   //          const res = await getInventoryReportDetailssApi(...filter);
   //          if (res.status === 200) {
   //             let cashbooks = res.data?.data?.Voucher
   //             let siteBalance = res.data?.data?.siteBalance
   //             let totalCount = res.data?.data?.totalCount
              
   //             const workbook = XLSX.utils.book_new();
   //             const worksheetData = [];
   //             const defaultHeight = [];
   //             const merges = [];
   //             const boldCellStyle = { font: { bold: true } }
   //             const borderCellStyle = {
   //                border: {
   //                   right: {
   //                      style: "thin",
   //                      color: "000000"
   //                   },
   //                   left: {
   //                      style: "thin",
   //                      color: "000000"
   //                   },
   //                   top: {
   //                      style: "thin",
   //                      color: "000000"
   //                   },
   //                   bottom: {
   //                      style: "thin",
   //                      color: "000000"
   //                   },
   //                }
   //             }
   //             const centerWithBoldStyle = {
   //                font: { bold: true },
   //                alignment: {
   //                   vertical: "center",
   //                   horizontal: "center",
   //                   wrapText: '1', // any truthy value here
   //                },
   //             }

   //             const defaultZooming = [
   //                { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 },
   //                { width: 20 }, { width: 20 }, { width: 20 }
   //             ];

   //             const addRow = (ele) => {
   //                worksheetData.push([
   //                   { v: ele?.approvedOn ? moment(ele?.approvedOn).format("DD/MM/YYYY") : "", s: borderCellStyle },
   //                   { v: ele?.createDate ? moment(ele?.createDate).format("DD/MM/YYYY") : '', s: borderCellStyle },
   //                   { v: ele?.voucherNumber || '', s: borderCellStyle },
   //                   { v: ele?.name || '', s: borderCellStyle },
   //                   { v: ele?.expenseType, s: borderCellStyle },
   //                   { v: ele?.amount ? formatPrice(ele?.amount) : "", s: borderCellStyle },
   //                   { v: ele?.siteBalance ? formatPrice(ele?.siteBalance) : '', s: borderCellStyle },
   //                   { v: ele?.remarks ? ele?.remarks : '', s: borderCellStyle },
   //                ]);
   //                defaultHeight.push({ hpx: 40 }, { hpx: 40 }, { hpx: 40 }, { hpx: 40 }, { hpx: 40 });
   //             };
   //             worksheetData.push(
   //                ["Approved","Created","Voucher ID","Particular","Expense type","Amount","Balance", "Remark",].map(item => ({ v: item, s: {...centerWithBoldStyle,...borderCellStyle} }))
   //             )
   //             cashbooks.forEach((ele) => {
   //                addRow(ele);
   //             });
   //             worksheetData.push([
   //                { v: 'Site Balance', s: { ...boldCellStyle, ...borderCellStyle } }, { v: "", s: borderCellStyle }, { v: formatPrice(siteBalance), s: { ...boldCellStyle, ...borderCellStyle } },

   //             ]);

   //             const worksheet = XLSX.utils.aoa_to_sheet([]);
   //             XLSX.utils.sheet_add_aoa(worksheet, worksheetData, { origin: "A1", cellStyles: true });
   //             worksheet['!cols'] = defaultZooming;
   //             worksheet['!rows'] = defaultHeight;
   //             worksheet['!merges'] = merges;
   //             XLSX.utils.book_append_sheet(workbook, worksheet, 'Cashbook');
   //             XLSX.writeFile(workbook, 'Cashbook.xlsx');
   //             setDownload(false);
   //             toast.success(res.data?.message)
   //          }
   //       } catch (error) {
   //          toast.error(error?.response?.data?.message || "Something went wrong")
   //          console.log("error", error);
   //       }
   //       setDownload(false);

   //    }


   // };



   return (
      <>
         <div className="main-content">
            <div className="container-fluid">
               <div className="row">
                  <div className="main-content">
                     {props.type !== "dashboard" && <div className="dash-nav1">
                        <div className="back"><span onClick={() => navigate(-1)} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> Back</span></div>
                        <div className="d-flex justify-content-between align-items-center">
                           <h3 className="mb-0 font-blue">Inventory Item Report</h3>
                        </div>
                     </div>}
                     {/* <div className="d-flex justify-content-end align-items-center">
                        <div className="d-flex ms-2">
                           <div className="d-block d-md-flex gap-2 align-items-center justify-content-end">
                           <div className="d-flex gap-2 align-items-center justify-content-center m-md-0">
                                 <label htmlFor="date" className="form-label">From:</label>
                                 <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={`form-control cursor-pointer ${cashbookFieldError && "border-danger"}`}
                                    selected={filterFormik.values?.to ? filterFormik.values?.to : null}
                                    onChange={(update) => {
                                       filterFormik.setFieldValue("to", update)
                                    }}
                                    placeholderText='dd/mm/yyyy'
                                    
                                 />
                              </div>
                              <div className=" d-flex gap-2 align-items-center justify-content-center m-md-0">
                              <label htmlFor="date" className="form-label">To:</label>
                                 <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    className={`form-control cursor-pointer ${cashbookFieldError && "border-danger"}`}
                                    selected={filterFormik.values?.from ? filterFormik.values?.from : null}
                                    onChange={(update) => {
                                       filterFormik.setFieldValue("from", update)
                                    }}
                                    placeholderText='dd/mm/yyyy'
                                 
                                 />
                              </div>
             
                              <button className="btn btn-dark me-2 mb-2 mb-md-0" type="button" onClick={() => { getInventoryReportDetails() }} >
                                 Search
                              </button>
                              {cashbook?.length > 0 && <button className="btn btn-dark me-2" disabled={download} type="button" onClick={exportToExcel} >
                                Download
                               </button>}
                           </div>
                        </div>
                     </div> */}
                     {loading ? <div className='d-flex align-items-center justify-content-center mt-4'>
                 <Spinwheel/>
                  </div>  : <div className='table-responsive mt-4'>
                        <table className="table table-bordered table-responsive">
                           <thead>   
                           <tr>
                                 <th scope='col' colspan="12" className='text-center'>{cashbook?.name}</th>
                              </tr>                           
                              <tr>
                                 <th scope='col' >Date</th>
                                 <th scope='col' >Challan ID</th>
                                 <th scope='col' >GIN ID</th>
                                 <th scope='col' >Approved By</th>
                                 <th scope='col' >From</th>
                                 <th scope='col' >Type</th>
                                 <th scope='col' >Quantity</th>
                              </tr>
                           </thead>
                           <tbody>
                                       {cashbook?.inOutStock?.length > 0 ? cashbook?.inOutStock?.map((ele, index) => {
                                          
                                          return (
                                             <tr>
                                                <td>{ele?.approvedOn ? moment(ele?.approvedOn).format("DD/MM/YYYY") : "-"}</td>
                                                <td>{ele?.type=="challan" && <Link to={`/challan/view/${ele?.challan?._id}`}>{ele?.challan?.challanNumber}</Link>} </td>
                                                <td>{(ele?.type=="gin" || ele?.type=='goods-return') && <Link to={`/gin/view/${ele?.type=="gin" ? ele?.gin?._id :ele?.goodsreturn?.returnFromGin?._id}`}>{ele?.type=="gin" ? ele?.gin?.ginNumber :  ele?.goodsreturn?.returnFromGin.ginNumber }</Link>} </td>
                                                <td className='text-capitalize'>{ele?.approvedBy?.firstName} {ele?.approvedBy?.lastName}</td>
                                                <td className='text-capitalize'>{ele?.type}</td>
                                                <td>{ele?.type=="gin" ? "OUT" : "IN"}</td>
                                                <td>{ele?.addedQty || ele?.quantity}</td>
                                             </tr>)
                                       }) : <tr className='text-center'>
                                          <td colSpan="12">
                                             <h1>Data Not Found</h1>
                                          </td>
                                       </tr>}
                                    </tbody>
                                    <tfoot>
                                       <div className='my-2'>
                                          <h5>Available quantity: {cashbook?.availableQty}</h5>
                                       </div>
                                    </tfoot>
                        </table>
                     </div>  
                  }

                  </div>
               </div>

               {totalCount > limit ? (
                  <div className="d-flex justify-content-center">
                     <ReactPaginate
                        nextLabel="Next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageCount={page}
                        previousLabel="< Previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName={"active"}
                        forcePage={searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0}
                     />
                  </div>
               ) : (
                  ""
               )}
            </div>
         </div>
         <CashbookFilter modalId={cashbookFilterModal} formik={filterFormik} clickHandler={handleFilterCashbook} />
         <ConfirmationModal modalId={confirmModalId} handleConfirm={handleVoucherApproval}
            message={<>Are you sure you want to <br /> Approve ?</>}
         />
         <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
         <StatusModal
            modalId={statusModalId}
            message={"Your Voucher is approved successfully."}
         />

      </>
   )
}

export default InventoryReport