import React, { useEffect, useState } from 'react'
import { approvePurchaseOrderApi, convertToPurchaseApi, getGinByIdApi, makeCopyApi,downloadPOPdf, approveGinByIdApi } from '../../utils/ApiEndpointFunctions'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../context/userContext';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import toast from 'react-hot-toast';
import Spinwheel from '../../components/common/Spinwheel';
import { formatPrice, getRoundOff } from '../../utils/HelperFuncitons';
import moment from 'moment';
import StatusModal from '../../components/modal/StatusModal';
import { S3_URL } from '../../utils/BaseUrl';

function ViewGin() {
  const params = useParams();
  const { userDetails } = useUserContext();
  const { id } = params;
  let addressKey = ["address1", "address2", "city", "state", "pinCode"]
  const [ginDetails, setGinDetails] = useState();
  const navigate = useNavigate();
  const confirmModalId = "conFirmModalID"
  const statusModalId = "statusModal"
  const confirmMakeAcopy = "confirmMakeAcopy";
  const convertToPurchaseModalId = "convertToPurchaseModalId"
  const [taxItems, setTaxItems] = useState({});
  const [loader, setLoader] = useState({
    approve: false,
    makeAcopy: false,
    convertToPurchase: false
  })
  const [confirmationMsg, setConfirmationMsg] = useState('')
  const [navigationRoute, setNavigationRoute] = useState("")
  const [download,setDownload] = useState(false)
  const location = useLocation();
  
  const previewTaxItems = (items) => {
    let taxItem = {};
    items?.forEach(item => {
      if (taxItem[item?.itemDetails?.tax]?.length > 0) {
        taxItem[item?.itemDetails?.tax].push(item)
      } else {
        taxItem[item?.itemDetails?.tax] = [item];
      }
    })
    setTaxItems(taxItem)
  }

  const getGinById = async (id) => {
    try{
      const res = await getGinByIdApi(id)
      
      if (res.status === 200) {
        const podata = res.data?.data
        setGinDetails(podata);
        previewTaxItems(podata?.items)
      }
    }catch(err){
      console.log(err)
      if (err.response && err.response.data) {
        toast.error(err.response.data.message)
      } else {
        toast.error("Something went wrong")
      }
    }
  }
  

  const avargeTax = (items) => {
    if (items?.length > 0) {
      let tax = 0;
      items.forEach(item => {

        tax += Number(item?.itemDetails.tax)
      })
      return tax / items.length
    }
    return 0;
  }

  const totalPrice = (items) => {

    if (items?.length > 0) {
      let price = 0;
      items.forEach(item => {

        price += Number(item?.unitPrice) * Number(item?.quantity)
      })
      return price
    }
    return 0;
  }

  const totalAmount = (items) => {
    if (items?.length > 0) {
      let amt = 0
      items.forEach(item => {

        amt += (Number(item?.unitPrice) * Number(item?.quantity)) * (100 + Number(item?.itemDetails?.tax)) / 100
      })
      return amt
    }
  }

  const handleGinApproval = async () => {
    try {
      const res = await approveGinByIdApi({ id: id })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmModalId).click()
        setConfirmationMsg(`GIN ${ginDetails?.ginNumber || ""} is successfully approved.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute('')
        getGinById(id)
      }
    } catch (error) {
      console.log(error)
      if(error && error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
        }else{
          toast.error("something went wrong");
        }
    }
  }

  const makeAcopyHandler = async () => {
    try {
      const res = await makeCopyApi({ id: ginDetails._id })
      if (res.status === 200) {
        toast.success(res.data?.message)
        document.getElementById(confirmMakeAcopy).click()
        setConfirmationMsg(`Purchase Order ${res.data?.data?.ginDetails[0]?.poNumber || ""} is successfully created.`)
        document.getElementById("statusModalBtn").click()
        if (res.data?.data?.ginDetails?.[0]?._id) {
          setNavigationRoute(`/purchase/update/${res.data?.data?.ginDetails?.[0]?._id}`)
        } else {
          setNavigationRoute("/purchase")
        }

      }
    } catch (error) {
      toast.error("something went wrong");
    }
  }



  const convertTopurchase = async () => {

    setLoader({ ...loader, convertToPurchase: true })
    try {
      const res = await convertToPurchaseApi({ purchaseOrderId: id })
      if (res.status === 200) {
        toast.success(res.data?.message);
        setConfirmationMsg(`Purchase Order ${ginDetails?.poNumber || ""} is successfully converted to purchase.`)
        document.getElementById("statusModalBtn").click()
        setNavigationRoute(`/challan/Edit/${res.data?.data?._id}`);
      }
    } catch (error) {
      if(error && error?.response?.status === 409 && error?.response?.data?.message) {
      toast.error(error?.response?.data?.message);
      }else{
        toast.error("something went wrong");
      }
    }
    setLoader({ ...loader, convertToPurchase: false })
  }

  const addressFormatter = (vendor) => {
    let address = ""

    addressKey.forEach((ele, index) => {
      if (vendor?.[ele]) {
        address += vendor?.[ele] + ((addressKey.length - 1) === index ? "" : ", ");
      }
    });
    return address
  }

  const handleCloseStatusModal = () => {
    if(navigationRoute){
      navigate(navigationRoute)
    }
  }

  useEffect(() => {
    if (id) {
      getGinById(id)
    }
  }, [])

  const getDownloadPO =async(id)=>{
    try {
      setDownload(true)
      const response = await downloadPOPdf(id)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', ginDetails?.poNumber ? `${ginDetails.poNumber}.pdf` :'po.pdf'); // or any other filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false)
      toast.success("Successfully downloaded");
    } catch (error) {
      setDownload(false)
      toast.error("Failed to download");
    }
  }


  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="back"><span onClick={() => navigate(location?.state?.path ? location?.state?.path : -1, { state: { ...location?.state } })} className="back cursor-pointer" ><i className="bi bi-chevron-left"></i> Back</span></div>
            <div className="main-content">
              
              <div className="row d-flex justify-content-center">
                <div className="card col-lg-10 col-md-12 col-xxl-11 my-3 p-5">
                
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <h1 className='text-capitalize'>
                        {ginDetails?.vendor?.name ? ginDetails?.vendor?.name : ""}

                      </h1>
                    </div>
                   
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">GIN Number</div>
                    <div>{ginDetails?.ginNumber}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Date of issue</div>
                    <div>{moment(ginDetails?.createDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">GST Number</div>
                    <div>{ginDetails?.vendor?.gstNumber}</div>
                  </div>
                  {ginDetails?.vendor?.type!="Vendor" && <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">PAN number</div>
                    <div className="light">{ginDetails?.vendor?.panNumber}</div>
                  </div>}
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Status</div>
                    <div className='text-capitalize'>{ginDetails?.status}</div>
                  </div>
                  <hr />
                  <div className='fs-3 fw-normal mt-2'>Address:</div>
                  <div className='mt-3'>{addressFormatter(ginDetails?.vendor?.vendorAddressId)}</div>
                  <hr />
                  <div className='fs-3 fw-normal'>
                    Site details
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">Site name</div>
                    <div>{ginDetails?.site?.[0]?.name}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="">Site address</div>
                    <div>{ginDetails?.site?.[0]?.addressId?.address1}</div>
                  </div>
                  <hr />
                  <div>
                    {ginDetails?.createdBy?.firstName && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Created By</div>
                      <div className='text-capitalize'>{ginDetails?.createdBy?.firstName || ""} {ginDetails?.createdBy?.lastName || ""}</div>
                    </div>}
                    {ginDetails?.createdAt && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Created On</div>
                      <div className='text-capitalize'>{ginDetails?.createdAt && moment(ginDetails?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {ginDetails?.status!="created" && ginDetails?.submittedBy?.firstName && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Submitted By</div>
                      <div className='text-capitalize'>{ginDetails?.submittedBy?.firstName || ""} {ginDetails?.submittedBy?.lastName || ""}</div>
                    </div>}
                    {ginDetails?.status!="created" && ginDetails?.submittedOn && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Submitted On</div>
                      <div className='text-capitalize'>{ginDetails?.submittedOn && moment(ginDetails?.submittedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                    {ginDetails?.approvedBy?.firstName && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Approved By</div>
                      <div className='text-capitalize'>{ginDetails?.approvedBy?.firstName || ""} {ginDetails?.approvedBy?.lastName || ""}</div>
                    </div>}
                    {ginDetails?.approvedOn && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Approved On</div>
                      <div className='text-capitalize'>{ginDetails?.approvedOn && moment(ginDetails?.approvedOn).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                  </div>
                  <hr />
                  <h3>
                    Items
                  </h3>
                  <div className='table-responsive'>
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">ITEMS NAME</th>
                          <th scope="col">UNIT</th>
                          <th scope="col">QTY</th>
                        </tr>
                      </thead>
                      <tbody >
                        {ginDetails?.items?.map((ele, index) => {
                          
                          return (
                            <tr key={index}>
                              <th>{ele?.name ? ele?.name : ele?.itemDetails?.name}</th>
                              {/* <td>{ele?.hsnCode ? ele?.hsnCode : ele?.itemDetails?.hsnCode}</td> */}
                              <td>{ele?.unitType ? ele?.unitType : ele?.itemDetails?.unitType}</td>
                              <td>{ele?.quantity}</td>
                              {/* <td>{ele?.tax ? ele?.tax : ele?.itemDetails?.tax}%</td>
                              <td>{formatPrice(ele?.quantity * ele?.unitPrice)}</td> */}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>

                  </div>
                  <hr />           
                  <div  className="">
                    <div className='fw-bold fs-6'>Sub-contractor Signature</div>
                    <div className="border border-2 d-flex justify-content-center align-items-center">
                      <img src={S3_URL + ginDetails?.signature} alt="" className='img-fluid' style={{ maxHeight: "200px" }} />
                    </div>
                  </div> 
                    
                  <div className="d-flex justify-contend-start gap-2">
                    {ginDetails?.status === "submitted" && userDetails?.roleId?.permission.includes("ginApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`}>Approve</div>
                    </div>}

                    {userDetails?.roleId?.permission.includes("ginUpdate") && ginDetails?.status?.toLowerCase() !== "approved" && <div className="d-flex justify-content-between gap-2 mt-3"> <button className='btn btn-primary' onClick={() => { navigate(`/gin/update/${id}`) }} > Edit</button></div>}
                    {userDetails?.roleId?.permission.includes("goodReturnWrite") && ginDetails?.status?.toLowerCase() == "approved" && <div className="d-flex justify-content-between gap-2 mt-3"> <button className='btn btn-primary' onClick={() => { navigate(`/gin/goods-return/${id}`) }} > Goods return</button></div>}

                  </div>
                </div>
              </div>

              {ginDetails?.goodsReturn?.map(goodsReturn=> <div className="row d-flex justify-content-center">
                <div className="card col-lg-10 col-md-12 col-xxl-11 my-3 p-5">
                
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <h4 className='text-capitalize'>
                        Goods Return

                      </h4>
                    </div>
                   
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mt-3">
                    <div className="fw-bold">Date of issue</div>
                    <div>{moment(goodsReturn?.createDate).format("DD/MM/YYYY")}</div>
                  </div>
                  <hr/>
                  <div>
                    {goodsReturn?.createdBy?.firstName && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Created By</div>
                      <div className='text-capitalize'>{goodsReturn?.createdBy?.firstName || ""} {goodsReturn?.createdBy?.lastName || ""}</div>
                    </div>}
                    {goodsReturn?.createdAt && <div className="d-flex justify-content-between mt-3">
                      <div className="fw-bold">Created On</div>
                      <div className='text-capitalize'>{goodsReturn?.createdAt && moment(goodsReturn?.createdAt).format("DD/MM/YYYY HH:mm")}</div>
                    </div>}
                  </div>
                  <hr />
                  <h3>
                    Items
                  </h3>
                  <div className='table-responsive'>
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th scope="col">ITEMS NAME</th>
                          <th scope="col">UNIT</th>
                          <th scope="col">QTY</th>
                        </tr>
                      </thead>
                      <tbody >
                        {goodsReturn?.items?.map((ele, index) => {
                          
                          return (
                            <tr key={index}>
                              <th>{ele?.name ? ele?.name : ele?.itemDetails?.name}</th>
                              {/* <td>{ele?.hsnCode ? ele?.hsnCode : ele?.itemDetails?.hsnCode}</td> */}
                              <td>{ele?.unitType ? ele?.unitType : ele?.itemDetails?.unitType}</td>
                              <td>{ele?.quantity}</td>
                              {/* <td>{ele?.tax ? ele?.tax : ele?.itemDetails?.tax}%</td>
                              <td>{formatPrice(ele?.quantity * ele?.unitPrice)}</td> */}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div  className="">
                    <div className='fw-bold fs-6'>Sub-contractor Signature</div>
                    <div className="border border-2 d-flex justify-content-center align-items-center">
                      <img src={S3_URL + goodsReturn?.signature} alt="" className='img-fluid' style={{ maxHeight: "200px" }} />
                    </div>
                  </div> 
                  <hr/>         
                  {goodsReturn?.remarks && <>
                    <div className='fs-3 fw-normal'>
                      Remarks:
                    </div>
                    <div className=''>{goodsReturn?.remarks}</div>
                    <hr />
                    </>}
                    
                  {/* <div className="d-flex justify-contend-start gap-2">
                    {ginDetails?.status === "submitted" && userDetails?.roleId?.permission.includes("ginApprove") && <div className="d-flex justify-content-between gap-2 mt-3">
                      <div className="btn btn-primary" data-bs-toggle="modal" data-bs-target={`#${confirmModalId}`}>Approve</div>
                    </div>}
                    {userDetails?.roleId?.permission.includes("ginUpdate") && ginDetails?.status?.toLowerCase() !== "approved" && <div className="d-flex justify-content-between gap-2 mt-3"> <button className='btn btn-primary' onClick={() => { navigate(`/gin/update/${id}`) }} > Edit</button></div>}
                    {userDetails?.roleId?.permission.includes("ginUpdate") && ginDetails?.status?.toLowerCase() == "approved" && <div className="d-flex justify-content-between gap-2 mt-3"> <button className='btn btn-primary' onClick={() => { navigate(`/gin/goods-return/${id}`) }} > Goods return</button></div>}

                  </div> */}
                </div>
              </div>)}
             
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal modalId={confirmModalId} handleConfirm={handleGinApproval} message={<>Are you sure you want to <br /> Approve ?</>} />
      <ConfirmationModal modalId={confirmMakeAcopy} handleConfirm={makeAcopyHandler} message={<>Are you sure you want to <br /> Make a Copy ?</>} />
      <ConfirmationModal modalId={convertToPurchaseModalId} handleConfirm={convertTopurchase} message={<>Are you sure you want to convert <br /> to  purchase ?</>}/>
      <button className='d-none' id="statusModalBtn" data-bs-toggle="modal" data-bs-target={`#${statusModalId}`} />
      <StatusModal
        modalId={statusModalId}
        message={confirmationMsg}
        handleClose={handleCloseStatusModal}
      />
    </>
  )
}

export default ViewGin